$(function(){
	if ($('#cadastro-newsletter').has('.form-rdstation').length && $('#cadastro-newsletter').attr("data-rd")!="loaded") {
		callFormNewsletter();
	}
});

function callFormNewsletter(){
	if($(this).scrollTop() > ($('#cadastro-newsletter').offset().top-$(window).height()+150)){
		$('#cadastro-newsletter').attr("data-rd","loaded");

		/* global RDStationForms */
		if (!$("#newsletter-footer-content-ff0921001df0bdb981bb").has(".bricks--component").length) {
			$.getScript('https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js',function(){
					new RDStationForms('newsletter-footer-content-ff0921001df0bdb981bb', 'UA-3953016-2').createForm();
					$("#cadastro-newsletter .container .row .loading").delay(500).fadeOut(300);
				}
			);
		}
	}
}
