!function(window){

    function loadImage (el, fn) {
        var img = new Image()
            , src = el.getAttribute('data-src');
        img.onload = function() {

            setImageByDataSourceType(el, src)

            fn? fn() : null;
        }
        img.src = src;
    }

    function setImageByDataSourceType(el, src) {

        var lazyEl = el;
        var dataSourceType = el.getAttribute('data-src-type');

        el.tagName.toLowerCase() == 'amp-img' ? 
            trySwitchAMPImageByDataSourceType(lazyEl, src, dataSourceType)
            :
            switchImageByDataSourceType(el, el, src, dataSourceType);
    }

    function switchImageByDataSourceType(lazyEl, el, src, dataSourceType) {
        switch(dataSourceType) {
            case 'bg-image':
                el.style.backgroundImage = "url("+src+")";
                break;
            default:
                el.src = src;
        }

        ['data-src', 'data-src-type'].forEach( function(att) { lazyEl.removeAttribute(att) } )
    }

    /* 
        This function is essential because: 
        1- The AMP functions may be loading delayded
        2- The AMP change yourself tag structure to show the image
    */
    function trySwitchAMPImageByDataSourceType(lazyEl, src, dataSourceType) {
        var intervalTime = 250, maxTries = 10;
        var currentTry = 0;

        var trySetElementSrc = function() {
            var el = lazyEl.querySelector('img');
            if(!el) return false

            switchImageByDataSourceType(lazyEl, el, src, dataSourceType)
            return true
        }

        if(trySetElementSrc()) return

        var idInterval = setInterval(function() {
            
            if(currentTry <  maxTries) currentTry+=1;
            else clearInterval(idInterval);

            if(trySetElementSrc()) clearInterval(idInterval);

        }, intervalTime)
    }

    function elementInViewport(el) {
        var rect = el.getBoundingClientRect()

        return (
            rect.top    >= 0
            && rect.left   >= 0
            && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
            && getComputedStyle(el, null).display !== 'none'
        )
    }

    var images = new Array()
        , query = document.querySelectorAll('.lazy[data-src]')
        , process_lazyload = function(){

        var remainingImages = []
        
        for (var i = 0; i < images.length; i++) {
            if (elementInViewport(images[i])) {
                loadImage(images[i]);
                continue;
            }
            remainingImages.push(images[i])
        };

        if(images.length === remainingImages.length) return;
        
        images = remainingImages
        if(!images.length) {
            removeEventListener('scroll',process_lazyload);
            removeEventListener('resize',process_lazyload);
        }

    };

    // Array.prototype.slice.call is not callable under our lovely IE8
    for (var i = 0; i < query.length; i++) {
        images.push(query[i]);
    };

    process_lazyload();
    addEventListener('scroll',process_lazyload);
    addEventListener('resize',process_lazyload);

}(this);