/*global isName, validateName */

var universidades_script = {
	init: function(){
		this.produto_emergencial();
		this.produto_sem_fiador();
		var usou;
		var jaCriado33 = false;

		function calculoSemFiador() {
			// Cálculo do box na versão sem Fiador
			$('.form-lp-cadastro #btnFormulario').on('click', function () {
				var valorMensalidade = document.getElementById("valorMensalidade").value;
				valorMensalidade = valorMensalidade.replace(".","");
				valorMensalidade = valorMensalidade.replace("R$","");
				valorMensalidade = valorMensalidade.replace(",",".");

				var valorPravalerSemfiador = (60 * valorMensalidade / 100) / 2;
				$('#valorSemPravalerNormal').text(mascaraMoeda(valorMensalidade.toString()));
				$('#valorSemPravalerMobile').text(mascaraMoeda(valorMensalidade.toString()));
				$('.valorComPravalerSemFiador').text(mascaraMoeda(valorPravalerSemfiador.toString()));
				$('#Simulacao').attr('style', 'transition: max-height 0.6s ease-in-out 0s; overflow: hidden;');
			});
		}
		//Método para enviar os dados da simulação ao Backoffice e preencher os dados da simulação sem juros
		function enviarDados(){

			var tamanhoTelaAtual = window.innerWidth;

			//Verifica se uma simulação já foi rodada. Caso sim, ele retira os cards do sem juros
			if(usou == true){

				//Reliza a retirada dos cards no mobile
				if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
					var textoAtual = document.getElementById("expandirNovoProduto").innerHTML;

					if(textoAtual == "ocultar"){
						document.getElementById("expandirNovoProduto").innerHTML = "expandir";
						document.getElementById("arrowNovoProduto").style.transform = "rotateZ(-315deg)";
					}

					var filhoASerRemovido1= document.getElementById("comPravalerNormalMobile");
					while (filhoASerRemovido1.hasChildNodes()) {
						filhoASerRemovido1.removeChild(filhoASerRemovido1.lastChild);

					}
					document.getElementById("comPravaler33Mobile").style.cssText="max-height:0px; overflow:hidden; transition: max-height 0.2s ease-in-out;"

					if(document.getElementById("expandir33mobile").innerHTML == "ocultar"){
						document.getElementById("expandir33mobile").innerHTML = "expandir";
						document.getElementById("arrow33Mobile").style.transform = "rotateZ(-315deg)";
					}

					document.getElementById("comPravalerNormalMobile").style.cssText="max-height:0px; overflow:hidden; transition: max-height 0.2s ease-in-out;"

					if(document.getElementById("expandirComMobile").innerHTML == "ocultar"){
						document.getElementById("expandirComMobile").innerHTML = "expandir";
						document.getElementById("arrowComMobile").style.transform = "rotateZ(-315deg)";
					}

				//Reliza a retirada dos cards no Desktop
				} else {
					var textoAtual = document.getElementById("expandirNovoProduto").innerHTML;

					if(textoAtual == "ocultar"){
						document.getElementById("expandirNovoProduto").innerHTML = "expandir";
						document.getElementById("arrowNovoProduto").style.transform = "rotateZ(-315deg)";
					}

					var filhoASerRemovido= document.getElementById("comPravalerNormalBody");
					while (filhoASerRemovido.hasChildNodes()) {
						filhoASerRemovido.removeChild(filhoASerRemovido.lastChild);

					}

					document.getElementById("comPravaler33Desktop").style.cssText="max-height:0px; overflow:hidden; transition: max-height 0.2s ease-in-out;"

					if(document.getElementById("expandir33").innerHTML == "ocultar"){
						document.getElementById("expandir33").innerHTML = "expandir";
						document.getElementById("arrow33").style.transform = "rotateZ(-315deg)";
					}

					document.getElementById("comPravalerNormal").style.cssText="max-height:0px; overflow:hidden; transition: max-height 0.2s ease-in-out;"

					if(document.getElementById("expandirCom").innerHTML == "ocultar"){
						document.getElementById("expandirCom").innerHTML = "expandir";
						document.getElementById("arrowCom").style.transform = "rotateZ(-315deg)";
					}
				}
			}

			//Faz validação de valor 0 como mensalidade
			if(document.getElementById("valorMensalidade").value == "R$ 0"){
				if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
					document.getElementById("valorMensalidade").style.cssText= "border-color: red !important";
					setTimeout(function(){
						document.getElementById("valorMensalidade").style.cssText= "";
					}, 500);
				}
				alert("Informe um valor diferente de zero.");
				return 0;
			}

			//Faz validação do nome no mobile
			if(document.getElementById("nomeAluno").value == ""){
				if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
					document.getElementById("nomeAluno").style.cssText= "border-color: red !important";
					setTimeout(function(){
						document.getElementById("nomeAluno").style.cssText= "";
					}, 500);
				}
				alert("Você deve informar o seu nome");
				return 0;
			}

			usuario = document.getElementById("emailAluno").value.substring(0, document.getElementById("emailAluno").value.indexOf("@"));
			dominio = document.getElementById("emailAluno").value.substring(document.getElementById("emailAluno").value.indexOf("@")+ 1, document.getElementById("emailAluno").value.length);

			//Faz validação do email no desktop
			if ((usuario.length >=1) &&
				(dominio.length >=3) &&
				(usuario.search("@")==-1) &&
				(dominio.search("@")==-1) &&
				(usuario.search(" ")==-1) &&
				(dominio.search(" ")==-1) &&
				(dominio.search(".")!=-1) &&
				(dominio.indexOf(".") >=1)&&
				(dominio.lastIndexOf(".") < dominio.length - 1)) {

			}
			else{
				//Faz validação do email no mobile
				if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
					document.getElementById("emailAluno").style.cssText= "border-color: red !important";
					setTimeout(function(){
						document.getElementById("emailAluno").style.cssText= "";
					}, 500);
				}
				alert("E-mail invalido");
				return 0;
			}

			if(document.getElementById("valorMensalidade").value == "" || document.getElementById("valorMensalidade").value == "R$" ){
				if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
					document.getElementById("valorMensalidade").style.cssText= "border-color: red !important";
					setTimeout(function(){
						document.getElementById("valorMensalidade").style.cssText= "";
					}, 500);
				}
				alert("Você deve informar o valor da sua mensalidade");
				return 0;
			}

			var valorMensalidade = document.getElementById("valorMensalidade").value;
				valorMensalidade = valorMensalidade.replace(".","");
				valorMensalidade = valorMensalidade.replace("R$","");
				valorMensalidade = valorMensalidade.replace(",",".");
			var tamanhoTelaAtual = window.innerWidth;
			var element = document.getElementById("Simulacao");

			//Realiza o aumento do tamanho da tela para dispositivos mobile para aparecer a div da simulação
			if (tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
				element.style.cssText="max-height:auto; transition: max-height 0.6s ease-in-out; overflow:hidden;";
				texto = document.getElementById("valorSemPravalerMobile").innerHTML;
			//Realiza o aumento do tamanho da tela para desktop para aparecer a div da simulação
			} else if (tamanhoTelaAtual >=767){
				element.style.cssText="max-height:auto; transition: max-height 0.6s ease-in-out;overflow:hidden;";
				texto = document.getElementById("valorSemPravalerNormal").innerHTML;
			}

			//Pega o valor passado no select de duração de anos
			var seleterQuantidadeMensalidade = document.getElementById("quantMensalidade");
			var quantMensalidade = $('select#quantMensalidade + ul > li[aria-selected="selected"]').attr('aria-value');
			var cardComPravaler= document.getElementById("comPravalerNormalBody");
			var cardComPravalerMobile= document.getElementById("comPravalerNormalMobile");


			var uri_unv = location.pathname.substr(1);
			if (uri_unv.indexOf('universidade-multivix') > 0) {
				var valorComPravalerNormal = valorMensalidade * porcentagem_sem_juros;

			} else{
				var valorComPravalerNormal = valorMensalidade / porcentagem_sem_juros;
			}
		
			form_data = {
				"action": "save_apipravaler_leadproposta",
				"nome": document.getElementById("nomeAluno").value,
				"email": document.getElementById("emailAluno").value,
				"cpf": 12345678909,
				"telefone": 99999999999,
				"url_origem": document.URL,
			};
			
			var getUrl = window.location;
			var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";

			$.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: form_data,})
			.done(function (data) {

				if (typeof data.errors != 'undefined') {
					alert('Ocorreu um erro ao enviar o formulário.');
					return 0;
				}

			}).fail(function (data) {
					alert('Ocorreu um erro ao enviar o formulário.');
					return 0;
				});

			valorComPravalerNormal = String(valorComPravalerNormal);
			var valorFiadorAluno   = valorMensalidade*2;
			valorMensalidade       = valorMensalidade.replace(".",",");
			valorFiadorAluno = String(valorFiadorAluno);
			var decimal1 = valorFiadorAluno.indexOf(".");

			//Realiza troca de ponto por vírgula em valores de mensalidade decimais
			if(decimal1 !== -1){
				valorFiadorAluno = Math.round(valorFiadorAluno*100)/100;
				valorFiadorAluno = parseFloat(valorFiadorAluno).toFixed(2);
				valorFiadorAluno = valorFiadorAluno.replace(".",",");
			}

			//Insere o valor necessário da soma entre aluno e fiador para mobile
			if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <=414){
				if (document.getElementById("valorSomaFiadorAlunoMobile") !== null) {
					document.getElementById("valorSomaFiadorAlunoMobile").innerHTML = "R$" + mascaraMoeda(valorFiadorAluno);
				}

			//Insere o valor necessário da soma entre aluno e fiador para desktop
			} else if (tamanhoTelaAtual > 414){
				if (document.getElementById("valorSomaFiadorAlunoDesk") !== null) {
					document.getElementById("valorSomaFiadorAlunoDesk").innerHTML = "R$" + mascaraMoeda(valorFiadorAluno);
				}
			}

			var cardsComPravalerNormal= $('#quantMensalidade').val()*2;
			var decimal = valorComPravalerNormal.indexOf(".");

			//Realiza a troca de ponto por vírgula em valores decimais para o produto sem juros
			if(decimal !== -1){
				valorComPravalerNormal = parseFloat(valorComPravalerNormal);
				valorComPravalerNormal= Math.round(valorComPravalerNormal*100)/100;
				valorComPravalerNormal = parseFloat(valorComPravalerNormal).toFixed(2);
				valorComPravalerNormal = valorComPravalerNormal.replace(".",",");
			}

			//Seta os valores com e sem pravaler tanto no mobile como no desktop para o primeiro card de cada lista.
			var valorComPravalerNormalHtml = mascaraMoeda(valorComPravalerNormal);

			// Se for modalidade repasse
			if(isRepasse())	valorComPravalerNormal = valorComPravalerNormalHtml = valorPrimeiroSemestreRepasse(valorMensalidade);
			
			if (document.getElementById("valorSemPravalerNormal") !== null) {
				document.getElementById("valorSemPravalerNormal").innerHTML = mascaraMoeda(valorMensalidade);
			}

			if (document.getElementById("valorComPravalerNormal") !== null) {
				document.getElementById("valorComPravalerNormal").innerHTML = valorComPravalerNormalHtml;
			}

			if (document.getElementById("valorSemPravalerMobile") !== null) {
				document.getElementById("valorSemPravalerMobile").innerHTML = mascaraMoeda(valorMensalidade);
			}

			if (document.getElementById("valorComPravalerNormalMobile") !== null) {
				document.getElementById("valorComPravalerNormalMobile").innerHTML = valorComPravalerNormalHtml;
			}

			//Realiza a inserção dos demais cards do produto sem juros
			for(x=2; x<=cardsComPravalerNormal; x++){
				var novaDiv = document.createElement('div');
				
				var valorParcelaSemestre = mascaraMoeda(valorComPravalerNormal);
				
				//Se for modalidade repasse
				if(isRepasse()) valorParcelaSemestre = valorMensalidadeRepassePorSemestre(valorMensalidade, x)
			
				var num_parcelas = 12;
				if (uri_unv.indexOf('universidade-multivix') > 0)
					num_parcelas = 10;

				novaDiv.innerHTML ="<div  class='textoComPravaler valoresComPravalerParcelas pb-0 pt-3 ' style='color:#454F63; text-align: center'><strong>Pague o <span style='color:#FEA104'>"+x+"º semestre em "+num_parcelas+" vezes <br/><span style='font-size: 26px; color:#454F63'> R$ "+valorParcelaSemestre+" <span id='valorComPravalerNormalParcelas'> </span> p/ mês</span></span></strong><hr class='mt-2 mb-0' style='border-top: solid 1px; color:#9E9E9E !important'></div>";
				if (tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
					cardComPravalerMobile.appendChild(novaDiv);
				} else if (tamanhoTelaAtual >767){
					cardComPravaler.appendChild(novaDiv);
				}
			}
			usou = true;

			expandirNovoProduto('expandirNovoProduto','arrowNovoProduto');
			expandir('expandirCom','arrowCom');
			expandir33('expandir33','arrow33');

			$('html, body').animate({
			    scrollTop: ($("#Simulacao").offset().top - 40)
			},300);	
		}

		//Método para expandir os cards do produto 33
		function expandir33(id,arrow){
			var tamanhoTelaAtual = window.innerWidth;
			var textoAtual = document.getElementById(id).innerHTML;
			var simulacao = document.getElementById("Simulacao");
			var seleterQuantidadeMensalidade = document.getElementById("quantMensalidade");
			var quantMensalidade = $('select#quantMensalidade + ul > li[aria-selected="selected"]').attr('aria-value');
			var containerSimulacao = document.getElementById("Simulacao").style.maxHeight;
			var corretor = 0;
			var cardComPravalerMobile33 = document.getElementById("comPravaler33MobileBody");

			//Pega os tamanhos dos cards do produto 33 no mobile
			if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
				container = document.getElementById("comPravaler33Mobile");
				aumentoIndividual = 100;
				coretorTela=1;
				cardComPravaler = document.getElementById("comPravaler33MobileBody").offsetHeight;
				corretor = 0;
			//Pega os tamanhos dos cards do produto 33 no Desktop
			} else if (tamanhoTelaAtual >767){
				container = document.getElementById("comPravaler33Desktop");
				aumentoIndividual = 94;
				cardComPravaler = document.getElementById("comPravaler33DesktopBody").offsetHeight;
				corretor = 40;
			}

			//Expande a div dos cards para mostrar os cards do produto 3
			if(textoAtual == "expandir"){
				container.style.cssText="max-height:3000px; overflow:hidden; transition: max-height 0.5s ease-in-out;"
				document.getElementById(id).innerHTML="ocultar";
				document.getElementById(arrow).style.transform = "rotateZ(225deg)";
				var multiplicador = (parseInt(quantMensalidade)-1) + parseInt(quantMensalidade);
				var aumentoTotal = (multiplicador*parseInt(aumentoIndividual));
				var aumentoTela = aumentoTotal+parseInt(containerSimulacao) + parseInt(corretor);
				simulacao.style.maxHeight = aumentoTela+"px";
			//Retrai a div dos cards para mostrar os cards do produto 3
			} else {
				container.style.cssText="max-height:0px; overflow:hidden; transition: max-height 0.2s ease-in-out;"
				document.getElementById(id).innerHTML = "expandir";
				document.getElementById(arrow).style.transform = "rotateZ(-315deg)";
				var multiplicador = (parseInt(quantMensalidade)-1) + parseInt(quantMensalidade);
				var telaAtual = document.getElementById("Simulacao").style.maxHeight;
				var dimunicaoTela = parseInt(containerSimulacao) - (multiplicador*parseInt(aumentoIndividual)) - parseInt(corretor);
				simulacao.style.maxHeight = dimunicaoTela+"px";
			}
		}

		//Método para expandir os cards com o protudo sem juros
		function expandir(id,arrow){
			var textoAtual = document.getElementById(id).innerHTML;
			var tamanhoTelaAtual = window.innerWidth;
			var container = document.getElementById("Simulacao").style.maxHeight;
			var aumentoIndividual =null;
			var coretorTela=0;
			var containerSimulacao = document.getElementById("Simulacao").style.maxHeight;

			//Tamanho de cada card individual no Galaxy
			if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <375){
				container = document.getElementById("comPravalerNormalMobile");
				aumentoIndividual = 85;
			//Tamanho de cada card individual no Desktop
			} else if (tamanhoTelaAtual >767){

				container = document.getElementById("comPravalerNormal");
				aumentoIndividual = 94;
			//Tamanho de cada card individual no Iphone
			} else if (tamanhoTelaAtual >=375 && tamanhoTelaAtual <767){
				container = document.getElementById("comPravalerNormalMobile");
				aumentoIndividual = 85;
				coretorTela=0;
			}

			var seleterQuantidadeMensalidade = document.getElementById("quantMensalidade");
			var quantMensalidade = $('select#quantMensalidade + ul > li[aria-selected="selected"]').attr('aria-value');
			var simulacao = document.getElementById("Simulacao");

			//Expansão da div com os cards com valores do produto sem juros
			if(textoAtual == "expandir"){
				container.style.cssText="max-height:2000px; overflow:hidden; transition: max-height 0.5s ease-in-out;"
				var multiplicador = (parseInt(quantMensalidade)-1) + parseInt(quantMensalidade);
				var aumentoTela = (multiplicador*parseInt(aumentoIndividual))+parseInt(containerSimulacao) - parseInt(coretorTela);
				simulacao.style.maxHeight = aumentoTela+"px";
				document.getElementById(id).innerHTML="ocultar";
				document.getElementById(arrow).style.transform = "rotateZ(225deg)";
				var multiplicador = (parseInt(quantMensalidade)-1) + parseInt(quantMensalidade);
				var aumentoTela = (multiplicador*parseInt(aumentoIndividual))+parseInt(containerSimulacao);
				simulacao.style.maxHeight = (aumentoTela-coretorTela)+"px";
				simulacao.style.overflow = 'hidden';
			//Retração da div com os cards com valores do produto sem juros
			} else {
				container.style.cssText="max-height:0px; overflow:hidden; transition: max-height 0.2s ease-in-out;"
				document.getElementById(id).innerHTML="expandir";
				document.getElementById(arrow).style.transform = "rotateZ(-315deg)";
				var multiplicador = (parseInt(quantMensalidade)-1) + parseInt(quantMensalidade);
				var telaAtual = document.getElementById("Simulacao").style.maxHeight;
				var dimunicaoTela = parseInt(telaAtual) - (multiplicador*parseInt(aumentoIndividual));
				simulacao.style.maxHeight = (dimunicaoTela)+"px";
			}
		}

		//Método para expandir div com os dados gerais do novo produto 33
		function expandirNovoProduto(id, arrow){
			var textoAtual = document.getElementById(id).innerHTML;
			var element = document.getElementById("Simulacao");
			var telaAtual = document.getElementById("Simulacao").style.maxHeight;
			var simulacao = document.getElementById("Simulacao");
			var tamanhoTelaAtual = window.innerWidth;
			var tamanhoContainerSimulacao = null;
			var id_texto;
			var valorSemPravalerPassar = null;
			var seleterQuantidadeMensalidade = document.getElementById("quantMensalidade");
			var quantMensalidade = $('select#quantMensalidade + ul > li[aria-selected="selected"]').attr('aria-value');
			var cardsComPravaler33Criar= quantMensalidade*2;
			var cardComPravaler33 = document.getElementById("comPravaler33DesktopBody");
			var cardComPravalerMobile33 = document.getElementById("comPravaler33MobileBody");
			var valorCom33Parcela1;
			var decimal;
			var tamanhoBox33;

			//Verifica se já foi feita uma simulação. Caso sim, remove os cards da simulação do produto 33 anterior
			if(jaCriado33 == true){
				if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
					var filhoASerRemovido= document.getElementById("comPravaler33MobileBody");
					while (filhoASerRemovido.hasChildNodes()) {
						filhoASerRemovido.removeChild(filhoASerRemovido.lastChild);
					}
				} else {
					var filhoASerRemovido= document.getElementById("comPravaler33DesktopBody");
					while (filhoASerRemovido.hasChildNodes()) {
						filhoASerRemovido.removeChild(filhoASerRemovido.lastChild);
					}
				}
			}

			//Pega valores para os dados do produto 33 para colocá-los no card no mobile
			if(tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
				tamanhoContainerSimulacao = 810;
				id_texto = "valorSemPravaler33mobile";
				valorSemPravalerPassar = document.getElementById("valorSemPravalerMobile").innerHTML;
				valorSemPravalerPassar = valorSemPravalerPassar.replace(".","");
				valorSemPravalerPassar = valorSemPravalerPassar.replace(",",".");
				document.getElementById(id_texto).innerHTML = valorSemPravalerPassar;
				valorParaFiadorGarantidor = valorSemPravalerPassar;
				valorParaFiadorGarantidor = valorParaFiadorGarantidor*2.2;
				valorParaFiadorGarantidor = String(valorParaFiadorGarantidor);
				var decimal1 = valorParaFiadorGarantidor.indexOf(".");

				//Realiza conversão para vírgula no caso de número decimais
				if(decimal1 !== -1){
					valorParaFiadorGarantidor = Math.round(valorParaFiadorGarantidor*100)/100;
					valorParaFiadorGarantidor = parseFloat(valorParaFiadorGarantidor).toFixed(2);
					valorParaFiadorGarantidor = valorParaFiadorGarantidor.replace(".",",");
				}

				document.getElementById("valorSomaFiadorAluno33mobile").innerHTML ="R$"+mascaraMoeda(valorParaFiadorGarantidor);
				valorCom33Parcela1 = (valorSemPravalerPassar * porcentagem_menor_parcela);
				valorCom33Parcela1 = String(valorCom33Parcela1);
				decimal = valorCom33Parcela1.indexOf(".");

				if(decimal !== -1){
					valorCom33Parcela1 = Math.round(valorCom33Parcela1*100)/100;
					valorCom33Parcela1 = parseFloat(valorCom33Parcela1).toFixed(2);
					valorCom33Parcela1 = valorCom33Parcela1.replace(".",",");
				}

				document.getElementById("valorComPravaler33mobile").innerHTML = mascaraMoeda(valorCom33Parcela1);
				tamanhoBox33 = document.getElementById("comPravaler33Mobile").offsetHeight;

			//Pega valores para os dados do produto 33 para colocá-los no card no desktop
			}
			else if (tamanhoTelaAtual >767) {

				tamanhoContainerSimulacao = 702;
				id_texto = "valorSemPravaler33";
				valorSemPravalerPassar = document.getElementById("valorSemPravalerNormal").innerHTML;
				valorSemPravalerPassar = valorSemPravalerPassar.replace(".","");
				valorSemPravalerPassar = valorSemPravalerPassar.replace(",",".");
				document.getElementById(id_texto).innerHTML = valorSemPravalerPassar;
				valorParaFiadorGarantidor = valorSemPravalerPassar;
				valorParaFiadorGarantidor = valorParaFiadorGarantidor*2.2;
				valorParaFiadorGarantidor = String(valorParaFiadorGarantidor);
				var decimal1 = valorParaFiadorGarantidor.indexOf(".");

				//Realiza conversão para vírgula no caso de número decimais
				if(decimal1 !== -1){
					valorParaFiadorGarantidor = Math.round(valorParaFiadorGarantidor*100)/100;
					valorParaFiadorGarantidor = String(valorParaFiadorGarantidor);
					valorParaFiadorGarantidor = valorParaFiadorGarantidor.replace(".",",");
				}

				document.getElementById("valorSomaFiadorAluno33Desk").innerHTML = "R$"+mascaraMoeda(valorParaFiadorGarantidor);
				valorCom33Parcela1 = (valorSemPravalerPassar * porcentagem_menor_parcela);
				valorCom33Parcela1 = String(valorCom33Parcela1);
				decimal = valorCom33Parcela1.indexOf(".");

				if(decimal !== -1){
					valorCom33Parcela1 = Math.round(valorCom33Parcela1*100)/100;
					valorCom33Parcela1 = String(valorCom33Parcela1);
					valorCom33Parcela1 = valorCom33Parcela1.replace(".",",");
				}

				document.getElementById("valorComPravaler33").innerHTML = mascaraMoeda(valorCom33Parcela1);
				tamanhoBox33 = document.getElementById("comPravaler33Desktop").offsetHeight;
			}

			//Faz inserção dos novos cards do produto 33 abaixo do primeiro card do produto
			for(x=2; x<=cardsComPravaler33Criar; x++){
				var meses;
				var valorComPravaler33Final;

				var uri_unv = location.pathname.substr(1);
				if (uri_unv.indexOf('universidade-multivix') > 0) {
					var parcelas = [
						{ "meses": 12, "porcentagem":50 },
						{ "meses": 10, "porcentagem":60 },
						{ "meses": 11, "porcentagem":60 },
						{ "meses": 12, "porcentagem":65 },
						{ "meses": 15, "porcentagem":65 },
						{ "meses": 15, "porcentagem":65 },
						{ "meses": 18, "porcentagem":65 },
						{ "meses": 18, "porcentagem":65 },
						{ "meses": 18, "porcentagem":65 }
					];

				} else {
					var parcelas = [
						{ "meses": 15, "porcentagem":40 },
						{ "meses": 12, "porcentagem":50 },
						{ "meses": 12, "porcentagem":50 },
						{ "meses": 15, "porcentagem":60 },
						{ "meses": 15, "porcentagem":65 },
						{ "meses": 18, "porcentagem":65 },
						{ "meses": 18, "porcentagem":65 },
						{ "meses": 18, "porcentagem":65 },
						{ "meses": 18, "porcentagem":65 }
					];
				}


				switch(x){
					case 2:
						meses = parcelas[0].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[0].porcentagem)/100;
						break;
					case 3:
						meses = parcelas[1].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[1].porcentagem)/100;
						break;
					case 4:
						meses = parcelas[2].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[2].porcentagem)/100;
						break;
					case 5:
						meses = parcelas[3].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[3].porcentagem)/100;
						break;
					case 6:
						meses = parcelas[4].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[4].porcentagem)/100;
						break;
					case 7:
						meses = parcelas[5].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[5].porcentagem)/100;
					case 8:
						meses = parcelas[6].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[6].porcentagem)/100;
					case 9:
						meses = parcelas[7].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[7].porcentagem)/100;
					case 10:
						meses = parcelas[8].meses;
						valorComPravaler33Final = (valorSemPravalerPassar*parcelas[8].porcentagem)/100;
						break;
				}

				valorComPravaler33Final = String(valorComPravaler33Final);
				var decimal = valorComPravaler33Final.indexOf(".");
				if(decimal !== -1){
					valorComPravaler33Final = Math.round(valorComPravaler33Final*100)/100;
					valorComPravaler33Final = parseFloat(valorComPravaler33Final).toFixed(2);
					valorComPravaler33Final = valorComPravaler33Final.replace(".",",");
				}

				var novaDiv = document.createElement('div');
				novaDiv.innerHTML ="<div class='textoComPravaler pb-0 pt-3' style='color:#454F63; text-align: center'><strong>Pague o <span style='color:#FEA104'>"+x+"º semestre em <span style='color:#8600FF'>"+meses+" vezes</span> <br/><span style='font-size: 26px; color:#454F63'> R$ "+mascaraMoeda(valorComPravaler33Final)+" <span id='valorComPravalerNormalParcelas'> </span> p/ mês</span></span></strong><hr class='mt-2 mb-0' style='border-top: solid 1px; color:#9E9E9E !important'></div>";

				if (tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
					cardComPravalerMobile33.appendChild(novaDiv);
				} else if (tamanhoTelaAtual >767){
					cardComPravaler33.appendChild(novaDiv);
				}
			}

			var novaDivFinal = document.createElement('div');
			novaDivFinal.innerHTML ="<div class='textoComPravaler pb-2 pt-3' style='color:#B90FB9 !important; text-align: center;font-style: italic !important';>As parcelas não se acumulam</div>";
			if (tamanhoTelaAtual >=360 && tamanhoTelaAtual <767){
				cardComPravalerMobile33.appendChild(novaDivFinal);
			} else if (tamanhoTelaAtual >767){
				cardComPravaler33.appendChild(novaDivFinal);
			}

			jaCriado33 = true;

			// Expande a div de simulação para mostrar o espaço do produto 33
			if(textoAtual == "expandir"){
				//document.getElementById(id).innerHTML="ocultar";
				//document.getElementById(arrow).style.transform = "rotateZ(225deg)";
				var aumentoTela = parseInt(telaAtual)+tamanhoContainerSimulacao+parseInt(tamanhoBox33);
				simulacao.style.maxHeight = aumentoTela+"px";
				var valorSemPravalerParaMostrar = valorSemPravalerPassar.replace(".",",");
				document.getElementById(id_texto).innerHTML=mascaraMoeda(valorSemPravalerParaMostrar);
			// Retrai a div de simulação para mostrar o espaço do produto 33$
			} else {
				//document.getElementById(id).innerHTML="expandir";
				//document.getElementById(arrow).style.transform = "rotateZ(-315deg)";
				var diminuicaoTela = parseInt(telaAtual)-tamanhoContainerSimulacao-parseInt(tamanhoBox33);
				simulacao.style.maxHeight = diminuicaoTela+"px";
			}
		}

		function mascaraMoeda(numero){
			var tamanho = numero.length;

			if(!(numero.includes(","))){
				if(tamanho>=4){
					var tresUltimosDigitos = numero.substr(tamanho-3,tamanho);
					var restoNumero = numero.substr(0,tamanho-3);
					var digitoFormatado = restoNumero+"."+tresUltimosDigitos+",00";
					return digitoFormatado;
				} else {
					return numero+",00";
				}
			}
			else {
				if(tamanho>=7){
					var cincoUltimosDigitos = numero.substr(tamanho-6,tamanho);
					var restoNumero = numero.substr(0,tamanho-6);
					var digitoFormatado = restoNumero+"."+cincoUltimosDigitos;
					return digitoFormatado;
				} else {
					return numero;
				}
			}
		}

		

		$("#form-proposal").on("submit", async function (e) {
			e.preventDefault();

			/*global valida_form_interesse*/
			/*eslint no-undef: "error"*/
      if(valida_form_interesse(e)) 
			{
				/*global envia_api_interesse_redirect_cadastro*/
				/*eslint no-undef: "error"*/
				envia_api_interesse_redirect_cadastro()
			}
    });


		

		String.prototype.reverse = function() { return this.split('').reverse().join(''); };

		function isRepasse() {
			return typeof simulador_repassse !== 'undefined'
		}

		function valorPrimeiroSemestreRepasse(valorMensalidade) {
			return (parseInt(valorMensalidade) * multiplicadores[1])
				.toLocaleString(
					'pt-BR', 
					{ minimumFractionDigits: 2, maximumFractionDigits: 2}
				);
		}

		function valorMensalidadeRepassePorSemestre(valorMensalidade, semestre) {
			return (parseInt(valorMensalidade) * multiplicadores[semestre])
				.toLocaleString(
					'pt-BR', 
					{ minimumFractionDigits: 2, maximumFractionDigits: 2}
				);
		}

		$('.bt_ver_mais').on('click',function(e){
			$(this).toggleClass('opened').parent().find("#text-avaliacao-mec").toggleClass('opened');
		});
		$('#btnFormulario').on('click', function(event) {
			event.preventDefault();

			var url = window.location.pathname;
			if (url.search('universidade-uniages') >= 0) {
				calculoSemFiador();
			} else {
				enviarDados();
			}
		});
		$('#SecaoComPravalerNormal')
			.on('click', function(event) {
				event.preventDefault();
				expandir('expandirCom','arrowCom');
			})
			.on('mouseover', function(event) {
				event.preventDefault();
				mudarCursor('SecaoComPravalerNormal');
			});
		$('#secao33ComPravaler')
			.on('click', function(event) {
				event.preventDefault();
				expandir33('expandir33','arrow33');
			})
			.on('mouseover', function(event) {
				event.preventDefault();
				mudarCursor('secao33ComPravaler');
			});
	},
	produto_emergencial: function(){

		function moeda2numero(valor){valor = valor.replace(".","");valor = valor.replace(",",".");return valor  }
		function numero2moeda(valor){return valor.toLocaleString('pt-br', {minimumFractionDigits: 2})}
		function validateName(nameAluno) {
			var re = /^\S+(\s\S+)+$/;
			return re.test(String(nameAluno).toLowerCase());
		}
		function validateEmail(email) {
		    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		    return re.test(String(email).toLowerCase());
		}

		$entre_contato              = $("#page div#content section#primary main#main section#simulacao-univ section.container-entre-contato")
		$emergencial                = $("#container-entre-contato-emergencial")
		$emergencial_bloco_veterano = $("#container-entre-contato-emergencial .bloco-btn-veterano")
		$emergencial_bloco_form     = $("#container-entre-contato-emergencial .bloco-form-simulacao")
		$bloco_simulacao_resultado  = $("#container-entre-contato-emergencial .bloco-simulacao-resultado")
		$Simulacao                  = $("#Simulacao")

		$emergencial_bloco_veterano.find(".bt-este-semestre").click(function(){
			$emergencial_bloco_veterano.hide();
			$Simulacao.hide();
			$emergencial_bloco_form.show();
		})

		$emergencial_bloco_veterano.find(".bt-outro-semestre").click(function(){
			$emergencial.hide();
			$emergencial_bloco_veterano.hide();
			$entre_contato.show();
		})

		$emergencial_bloco_form.find(".voltar").click(function(){
			$emergencial_bloco_form.hide();
			$entre_contato.hide();
			$bloco_simulacao_resultado.hide();
			$Simulacao.hide();
			$emergencial_bloco_veterano.show();

			$('html, body').animate({
			    scrollTop: ($("#container-entre-contato-emergencial .bloco-btn-veterano").offset().top - 40)
			},300);
		})

		$entre_contato.find(".voltar").click(function(){
			$emergencial_bloco_form.hide();
			$entre_contato.hide();
			$bloco_simulacao_resultado.hide();
			$Simulacao.hide();
			$emergencial.show();
			$emergencial_bloco_veterano.show();

			$('html, body').animate({
			    scrollTop: ($("#container-entre-contato-emergencial .bloco-btn-veterano").offset().top - 40)
			},300);
		})

		$emergencial_bloco_form.find(".btn").click(function(){

			$nomeAluno =  $emergencial_bloco_form.find(".nomeAluno input").val()
			$emailAluno =  $emergencial_bloco_form.find(".emailAluno input").val()
			$preco_formatado = $emergencial_bloco_form.find(".valorMensalidade input").val()
			$instituicao = $emergencial_bloco_form.find(".instituicao").val()

			$porcent_prod_metade = $('input[name="metade_mensalidade_valor"]').val()
			$porcent_prod_menos = $('input[name="menos_mensalidade_valor"]').val()

			if($nomeAluno == ""){
				alert("Você deve informar o seu nome");
				return 0;
			}

			if(!validateEmail($emailAluno)){
				alert("Informe um e-mail válido");
				return 0;
			}

			if($preco_formatado == "R$ 0" || $preco_formatado == ""){
				alert("Informe um valor diferente de zero.");
				return 0;
			}

			form_data = {
				"action": "save_apipravaler_leadproposta",
				"nome": $nomeAluno,
				"email": $emailAluno,
				"cpf": 12345678909,
				"telefone": 99999999999,
				"url_origem": document.URL,
			};
			
			var getUrl = window.location;
			var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";

			$.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: form_data,})
			.done(function (data) {

				if (typeof data.errors != 'undefined') {
					alert('Ocorreu um erro ao enviar o formulário.');
					return 0;
				}

			}).fail(function (data) {
					alert('Ocorreu um erro ao enviar o formulário.');
					return 0;
			});	

			$preco = moeda2numero($preco_formatado)
			$limite = Math.ceil($preco * 2)
			$limite_formatado =  numero2moeda($limite)
			$produto1_parcela = $porcent_prod_metade * $preco / 100;
			$produto1_parcela_formatado = numero2moeda($produto1_parcela)
			$produto2_parcela = $porcent_prod_menos * $preco / 100;
			$produto2_parcela_formatado = numero2moeda($produto2_parcela)

			$bloco_simulacao_resultado.find("section.produto ul.resultados li.semprava div.box p.valores span.valor").html($preco_formatado)
			$bloco_simulacao_resultado.find("section.sem-juros ul.resultados li.comprava div.box p.valores span.valor").html($produto1_parcela_formatado)
			$bloco_simulacao_resultado.find("section.menor-parcela ul.resultados li.comprava div.box p.valores span.valor").html($produto2_parcela_formatado)
			$bloco_simulacao_resultado.find("section.produto p.footer-legal span.limite").html($limite_formatado)
			$bloco_simulacao_resultado.find("section.menor-parcela ul.resultados li.comprava span.calculo-porcentagem").html($porcent_prod_menos);

			$bloco_simulacao_resultado.slideDown(300, function(){
				$('html, body').animate({
				    scrollTop: ($("#container-entre-contato-emergencial .bloco-simulacao-resultado").offset().top - 40)
				},300);	
			});
		})
	},
	produto_sem_fiador: function(){
		function moeda2numero(valor){valor = valor.replace(".","");valor = valor.replace(",",".");return valor  }
		function numero2moeda(valor){return valor.toLocaleString('pt-br', {minimumFractionDigits: 2})}	
		function validateName(nameAluno) {
			var re = /^\S+(\s\S+)+$/;
			return re.test(String(nameAluno).toLowerCase());
		}
		function validateEmail(email) {
		    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		    return re.test(String(email).toLowerCase());
		}

		$("#container-entre-contato-sem-fiador .bloco-btn-emergencial").find("label.bt-este-semestre").click(function(){
			save_event.in_ga('pagIES - Sem Fiador', 'Estou Estudando')
			$("#container-entre-contato-sem-fiador .bloco-btn-emergencial").hide();
			$("#container-entre-contato-sem-fiador .bloco-form-simulacao").show();
			$("#container-entre-contato-sem-fiador .bloco-form-simulacao").find("div#form_estou_estudando").show();
			$('html, body').animate({
				scrollTop: ($("#container-entre-contato-sem-fiador .bloco-form-simulacao").offset().top - 60)
			},300);
		});

		$("#container-entre-contato-sem-fiador .bloco-btn-emergencial").find("label.bt-outro-semestre").click(function(){
			save_event.in_ga('pagIES - Sem Fiador',  'Vou Estudar')
			$("#container-entre-contato-sem-fiador .bloco-sem-fiador").show();
			$("#container-entre-contato-sem-fiador .bloco-sobre-sem-fiador").show(); 
			$("#container-entre-contato-sem-fiador .bloco-btn-emergencial").hide();
			$('html, body').animate({
				scrollTop: ($("#container-entre-contato-sem-fiador .bloco-sem-fiador").offset().top - 60)
			},300);
		});

		$("#container-entre-contato-sem-fiador").find('div.bloco-sem-juros label.btn').click(function(){
			save_event.in_ga('pagIES - Sem Fiador',  'Vou Estudar', 'COM Fiador')
		});

		$("#container-entre-contato-sem-fiador").find('div.bloco-com-juros label.btn').click(function(){
			save_event.in_ga('pagIES - Sem Fiador',  'Vou Estudar', 'SEM Fiador')
		})

		$("#container-entre-contato-sem-fiador .bloco-sem-fiador").find("label.btn").click(function(){
			$("#container-entre-contato-sem-fiador .bloco-sem-fiador").hide();
			$("#container-entre-contato-sem-fiador .bloco-sobre-sem-fiador").hide();
			$("#container-entre-contato-sem-fiador .bloco-form-simulacao").show();
			$("#container-entre-contato-sem-fiador .bloco-form-simulacao").find("div#form_vou_estudar").show();
			$(this).addClass("active");
			$('html, body').animate({
				scrollTop: ($("#container-entre-contato-sem-fiador .bloco-form-simulacao").offset().top - 60)
			},300);
		});

		$("#bloco-sem-fiador-finalizacao").find('.close').click(function(){
			$("#bloco-sem-fiador-finalizacao").hide(); 
		});

		$("#container-entre-contato-sem-fiador .bloco-form-simulacao").find("#form_estou_estudando").find("label.btn").click(function(){
			$nomeAluno =  $("#container-entre-contato-sem-fiador .bloco-form-simulacao").find("#form_estou_estudando .nomeAluno input").val()
			$emailAluno =  $("#container-entre-contato-sem-fiador .bloco-form-simulacao").find("#form_estou_estudando .emailAluno input").val()
			$preco_formatado = $("#container-entre-contato-sem-fiador .bloco-form-simulacao").find("#form_estou_estudando .valorMensalidade input").val()
			$instituicao = $("#container-entre-contato-sem-fiador .bloco-form-simulacao").find("#form_estou_estudando .instituicao").val()
			$porcent_prod_metade = $("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").find('input[name="metade_mensalidade_valor"]').val()
			$porcent_prod_menos = $("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").find('input[name="menos_mensalidade_valor"]').val()
			if(!validateName($nomeAluno)){
				alert("Você deve informar o seu nome");
				return 0;
			}
			if(!validateEmail($emailAluno)){
				alert("Informe um e-mail válido");
				return 0;
			}
			if($preco_formatado == "R$ 0" || $preco_formatado == ""){
				alert("Informe um valor diferente de zero.");
				return 0;
			}

			var getUrl = window.location;
			var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";

			form_data = {
				"action": "save_apipravaler_leadproposta",
				"nome": $nomeAluno,
				"email": $emailAluno,
				"cpf": 12345678909,
				"telefone": 99999999999,
				"url_origem": document.URL,
			};
		
			$.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: form_data,})
			.done(function (data) {

				if (typeof data.errors != 'undefined') {
					alert('Error, por favor verifique os campos e preencha conforme o solicitado.');
					return 0;
				}
		
			}).fail(function (data) {
				alert('Ocorreu um erro ao enviar o formulário.');
				return 0;
			});

			$preco = moeda2numero($preco_formatado);
			$limite = Math.ceil($preco * 2);
			$limite_formatado =  numero2moeda($limite);
			$produto1_parcela = $porcent_prod_metade * $preco / 100;
			$produto1_parcela_formatado = numero2moeda($produto1_parcela);
			$produto2_parcela = $porcent_prod_menos * $preco / 100;
			$produto2_parcela_formatado = numero2moeda($produto2_parcela);
			$("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").find("section.produto ul.resultados li.semprava div.box p.valores span.valor").html($preco_formatado)
			$("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").find("section.sem-juros ul.resultados li.comprava div.box p.valores span.valor").html($produto1_parcela_formatado)
			$("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").find("section.menor-parcela ul.resultados li.comprava div.box p.valores span.valor").html($produto2_parcela_formatado)
			$("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").find("section.produto p.footer-legal span.limite").html($limite_formatado)
			$("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").find("section.menor-parcela ul.resultados li.comprava span.calculo-porcentagem").html($porcent_prod_menos);
			$("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").slideDown(300, function(){
				$('html, body').animate({
				    scrollTop: ($("#container-entre-contato-sem-fiador .bloco-simulacao-resultado").offset().top - 40)
				},300);	
			});
		});

		$("#container-entre-contato-sem-fiador .bloco-form-simulacao").find("#form_vou_estudar").find("label.btn").click(function(){

			$nomeAluno     = $("#container-entre-contato-sem-fiador .bloco-form-simulacao").find(".nomeAluno input").val();
			$emailAluno    = $("#container-entre-contato-sem-fiador .bloco-form-simulacao").find(".emailAluno input").val();
			$cpfAluno      = $("#container-entre-contato-sem-fiador .bloco-form-simulacao").find(".cpfAluno input").val();
			$telefoneAluno = $("#container-entre-contato-sem-fiador .bloco-form-simulacao").find(".telefoneAluno input").val();
			$card_fiador   = "";
			if(!validateName($nomeAluno)){
				alert("Você deve informar o seu nome completo");
				return 0;
			}
			if(!validateEmail($emailAluno)){
				alert("Informe um e-mail válido");
				return 0;
			}
			if(!isCPF($cpfAluno)){
				alert("Você deve informar um CPF válido");
				return 0;
			}
			if($telefoneAluno == ""){
				alert("Você deve informar o seu telefone");
				return 0;
			}
			var $bloco_fiador = $("#container-entre-contato-sem-fiador .bloco-sem-fiador");	
			if($bloco_fiador.find(".bloco-sem-juros label.active")[0] == undefined) {
				$card_fiador = "SIMULADOR - SEM FIADOR";
			}else{
				$card_fiador = "SIMULADOR - COM FIADOR";
			}

			var getUrl = window.location;
			var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";
			
			form_data = {
				"action": "save_apipravaler_leadproposta",
				"nome": $nomeAluno,
				"email": $emailAluno,
				"cpf": $cpfAluno,
				"telefone": $telefoneAluno.replace(/\D/g,''),
				"url_origem": document.URL,
			};

			$.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: form_data,})
			.done(function (data) {

				if (typeof data.errors == 'undefined') {
					var $bloco_fiador = $("#container-entre-contato-sem-fiador .bloco-sem-fiador");
					if($bloco_fiador.find(".bloco-sem-juros label.active")[0] == undefined) {
						var $cta = $('#bloco-sem-fiador-finalizacao #msg-sem-fiador a');
						$("#bloco-sem-fiador-finalizacao #msg-sem-fiador").show();
					}else{
						var $cta = $('#bloco-sem-fiador-finalizacao #msg-com-fiador a');
						$("#bloco-sem-fiador-finalizacao #msg-com-fiador").show();
					}
					if($cta){
						var url = $cta.attr("href");
						url = url.replace("###",data.token);
						$cta.attr("href",url);
					}	
					$("#container-entre-contato-sem-fiador .bloco-form-simulacao").hide();
					$("#bloco-sem-fiador-finalizacao").show();
					$("#container-entre-contato-sem-fiador .bloco-form-simulacao").find(".nomeAluno input").val('');
					$("#container-entre-contato-sem-fiador .bloco-form-simulacao").find(".emailAluno input").val('');
					$("#container-entre-contato-sem-fiador .bloco-form-simulacao").find(".cpfAluno input").val('');
					$("#container-entre-contato-sem-fiador .bloco-form-simulacao").find(".telefoneAluno input").val('');
				} else {
					alert('Error, por favor verifique os campos e preencha conforme o solicitado.');
				}

			}).fail(function (data) {
					data = data.responseJSON;
					var msg = '';
					if (data.errors.nome) msg += data.errors.nome + '\n';	
					if (data.errors.email) msg += data.errors.email + '\n';
					if (data.errors.cpf) msg += data.errors.cpf + '\n';
					alert(msg);
				});
		})
	}
}
page_scripts['page-unv-single'] = universidades_script;
