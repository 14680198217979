var requestLikes = (function () {

    function initialize() {

        // só verifica se existe um cookie, se estiver em uma single-post
        if($('body.single-post').length){
            verify_cookie();
        }

        $('.btn-like-post').on('click', function(event){
            event.preventDefault();

            $('.btn-like-post').addClass('disabled');
            $('.btn-like-post').addClass('animated-pulse');

            $id_post = $('.btn-like-post').attr('data-id');
            var name_cookie = 'like-post-' + $id_post;
            
            set_cookie(name_cookie, $id_post);

            blog_process_like($id_post);
        });
    }

    function blog_process_like($id_post){
        var data = {
            'action': 'ajax_blog_process_like',
            'id_post': $id_post,
            'security': securityAjax
        }

        $.post(ajaxurl, data, function (like_count){
            $('.count-likes').html(like_count);
        });
    }

    function set_cookie(name_cookie, id_post){
        var expiration_value = 7; // 7 Dias
        var key_value = 'cookie do post ' + id_post;

        var date = new Date();
        date.setTime(date.getTime() + expiration_value*24*60*60*1000);

        var expires = date.toUTCString();

        document.cookie = name_cookie + "=" + key_value + "; expires=" + expires + "; path=/";
    }

    function verify_cookie(){
        $id_post = $('.btn-like-post').attr('data-id');
        var name_cookie = 'like-post-' + $id_post;

        var cookies_list = document.cookie;
        var get_cookie = cookies_list.indexOf(name_cookie);

        if(get_cookie >= 0){
            $('.btn-like-post').addClass('disabled');
        }
    }

    return {
        "init": initialize
    }
})();
requestLikes.init();