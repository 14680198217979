(function () {

    var targetElement = document.querySelector('.form');
    if(!targetElement) return;

    var btnElement = document.querySelector('.simulate-btn');
    if(!btnElement) return;
    // btnElement.addEventListener("click", function() { toTarget() });

    var navBar = document.querySelector("#main-nav")

    var percentAdjust = .3;

    var targetInViewport = function() {
        var rect = targetElement.getBoundingClientRect()
        var viewHeight = (window.innerHeight || document.documentElement.clientHeight)

        var adjustedHeight = percentAdjust * rect.height;
        var top = viewHeight - adjustedHeight;
        var bottom = -rect.height + adjustedHeight;

        return rect.top <= top && rect.top >= bottom;
    }

    var tryShowBtnElement = function() {
        if(targetInViewport()) {
            btnElement.style.visibility = 'hidden'
            btnElement.classList.remove("open-animation");
            document.body.classList.remove("open-bt-bottom");
            return;
        }
        btnElement.style.visibility = 'visible';
        btnElement.classList.add("open-animation");
        document.body.classList.add("open-bt-bottom");
    }

    var toTarget = function() {       
        var rect = targetElement.getBoundingClientRect()
        var y = rect.top + window.scrollY - (navBar ? navBar.clientHeight : 0);
            window.scroll({
            top: y,
            behavior: 'smooth'
        });
    }

    tryShowBtnElement();
    addEventListener('scroll', tryShowBtnElement);
    addEventListener('resize', tryShowBtnElement);

})();