/*global isName, isEmail, isTel, isCPF, isChecked, valida_global_interesse, page_scripts*/
/*eslint no-undef: "error"*/
/*eslint complexity: ["error", 10]*/
/*eslint max-depth: ["error", 7]*/
/*eslint max-len: ["error", { "ignoreStrings": true, "code": 170, "tabWidth": 9 }]*/
/*eslint max-lines-per-function: ["error", {"max": 41, "skipComments": true}]*/
/*eslint max-nested-callbacks: ["error", 3]*/
/*eslint max-params: ["error", 10]*/
/*eslint max-statements: ["error", 20]*/
/*eslint-env es6*/
/*eslint max-lines-per-function: ["error", 150]*/

let tokenAuthApiInteresseOld;

var form_api_interesse = {


	init: function(){	

		document.getElementById("bt-enviar-interesse").addEventListener("click", function(){ 
			run_form_interesse();
		});

		document.getElementById("telefone").addEventListener("keyup", function(){ 
			checkMaxLenght(this);
		});

		document.getElementById("cpf").addEventListener("keyup", function(){ 
			checkMaxLenght(this); 
		});		

		document.getElementById("btn-show-form-interesse").addEventListener("click", function(){ 
			document.getElementById("bloco-hero-lead").style.display = "block";
		});

		document.getElementById("btn-close-form").addEventListener("click", function(){ 
			document.getElementById("bloco-hero-lead").removeAttribute("style");
		});

		document.getElementById("close-modal-interesse").addEventListener("click", function(){ 
			document.getElementById("bloco-hero-lead").removeAttribute("style");
		});

		$("#bloco-hero-lead #form-interesse input").on("click keyup", function () {
			verifyFillFormLead(this);
		});

		function run_form_interesse() {   
			const fields = [];
			fields["nome"] = { required:true, name:"Nome", validateFunction:isName};
			fields["email"] = { required:true, name:"E-mail", validateFunction:isEmail};
			fields["telefone"] = { required:true, name:"Celular", validateFunction:isTel};
			fields["cpf"] = { required:true, name:"CPF", validateFunction:isCPF};
			fields["politicas"] = { required:true, name:"Leia as nossas Políticas de Privacidade antes de prosseguir! Envio", validateFunction:isChecked};
			let form = document.getElementById("form-interesse");

			if (valida_global_interesse(form, fields)) {
				apiInteresse();
			}

			return false;
		}

		function apiSaveData(baseUrl){
			let form_data = 'action=save_apipravaler_leadproposta'
					+ '&nome=' + document.getElementById("nome").value
					+ '&email=' + document.getElementById("email").value
					+ '&telefone=' + document.getElementById("telefone").value.replace(/[^0-9]/g, "")
					+ '&cpf=' + document.getElementById("cpf").value
					+ '&url_origem=' + window.location.href.replace(/&/gi, "%26");

			var xhttp = new XMLHttpRequest();
			xhttp.responseType = 'json';

			xhttp.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					toggleChecked("modal-interesse-bloco-hero-lead");

					let origin = window.location.href;
					origin = (origin.indexOf("?") > 0) ? origin.substring(0, origin.indexOf("?")) : origin;
					let bt = document.querySelector("#href-lead a");
					let proposalUrl = (getParam() != "") ? bt.href + '?' + getParam() : bt.href;
					let operator = (proposalUrl.indexOf("?") !== -1) ? "&" : "?"
					let new_url = proposalUrl + operator + 'origin=' + origin + '&token='+xhttp.response.token;
					
					bt.href = new_url;

					resetForm();
				} else {
					let fieldGeneral = document.getElementById("feedback-message-general");
					let errorText = xhttp.response ? ' Erro: ' + JSON.stringify(xhttp.response.errors, null, " - ") : "";
					errorText = errorText.replace(/[{}\"\\]/gm,"");
					errorText = 'Favor verifique todos os campos e tente novamente.' + errorText;
					fieldGeneral.textContent = errorText;
					fieldGeneral.classList.add("group-error");
				}
			};

			xhttp.open("POST", baseUrl + "wp-admin/admin-ajax.php", true);
			xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhttp.send(form_data);
		}
		
		function apiInteresse() {
			var getUrl = window.location;
			var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";
		
			apiSaveData(baseUrl);
		}

		function toggleChecked(input){
			document.getElementById(input).checked = !document.getElementById(input).checked;
		}

		function getParam() {
			let href = window.location.href

			return href.lastIndexOf("?") > 0 ? href.substring(href.lastIndexOf("?") + 1) : ""
		}

		function setParams() {
			let btMenu = document.querySelector('#menu-interno .menu-bt')
			let btModal = document.querySelector('#bloco-hero-lead .modal-parcelas .bt')
			let btHero = document.querySelector('#bloco-hero-lead .bt-hero')
			let btFixed = document.getElementById('fixed-cta-mobile')
			let btFacaTudoOnline = document.querySelector('#bloco-cem-porcento .cadastro')

			let origin = window.location.href;
			origin = (origin.indexOf("?") > 0) ? origin.substring(0, origin.indexOf("?")) : origin;

			let proposalUrl = (getParam() != "") ? btMenu.href + '?' + getParam() : btMenu.href;
			if(proposalUrl.indexOf("origin=") == -1) {
				proposalUrl = (proposalUrl.indexOf("?") > 0) ? proposalUrl + '&origin=' + origin : proposalUrl + "?origin=" + origin;
			}

			btMenu.href = proposalUrl
			btModal.href = proposalUrl
			btHero.href = proposalUrl
			btFacaTudoOnline.href = proposalUrl
			btFixed.href = proposalUrl

		}

		function resetForm(){
			document.getElementById("nome").value = "";
			document.getElementById("email").value = "";
			document.getElementById("telefone").value = "";
			document.getElementById("cpf").value = "";
			let fieldGeneral = document.getElementById("feedback-message-general");
			fieldGeneral.innerHTML = ""
		}

		function checkMaxLenght(el){
			var MaxLenght = el.getAttribute("data-maxlength");
			var val = onlyNumbers(el.value);
			el.value = val.substr(0, MaxLenght)
		}

		function onlyNumbers(Str){
			return Str.replace(/\D/g, '');
		}

		function verifyFillFormLead(el) {
			let nome = document.getElementById("nome").value;
			let email = document.getElementById("email").value;
			let telefone = document.getElementById("telefone").value;
			let cpf = document.getElementById("cpf").value;
			let ckbPoliticas = document.getElementById("politicas").checked;
			let fieldGeneral = document.getElementById("feedback-message-general");

			if (nome == "" || email == "" || telefone == "" || cpf == "" || !ckbPoliticas) {
				fieldGeneral.innerHTML = "Para continuar, preencha os dos campos do formulário acima.";
				return false;
			} else {
				fieldGeneral.innerHTML = "";
			}
		}
	}
};
//page_scripts['blog-pravaler'] = form_api_interesse; 