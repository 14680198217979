/*global Swiper*/

// Global Variables
let swiperVantagem;
let swiperHistory;
let swiperDuvidas;
let swiperBlocoHeroAnos;
let swiperBlocoHeroCards;
let swiperPassosCadastro;
let swiperSobre;
let swiperCarouselUnivs;
let swiperCarouselCourses;

var home_script = {
    init: function(){
        this.initGeoLocation();
        this.initAutoState();
        this.mapMediaSwiper();

        var mediaQ = "";
        jQuery(document).ready(function(){
            var seta=false, lw=60, Mn=$(".blco01");
            $(window).on("scroll",function(e){
                if($(this).scrollTop()>=lw&&!seta){
                    seta=true;
                    Mn.addClass("fade-seta");
                }
                else if($(this).scrollTop()<lw){
                    seta=false;
                    Mn.removeClass("fade-seta");
                }
            });
        });

        $("#form-proposal").on("submit", async function (e) {
			e.preventDefault();

            /*global valida_form_interesse*/
            /*eslint no-undef: "error"*/
            if(valida_form_interesse(e))
                    {
                /*global envia_api_interesse_redirect_cadastro*/
                /*eslint no-undef: "error"*/
                        envia_api_interesse_redirect_cadastro()
                    }
        });
    },
    initAutoState: function(){
        let region_code = 'SP';
        let region_long_name = 'São Paulo';

        if (home_script.getCookie('selectedUf') != "") {
            region_code = home_script.getCookie('selectedUf');
            region_long_name = home_script.getCookie('selectedUfName');
        }

        home_script.getStateSelectedIes(region_code, region_long_name);
        home_script.getStateSelectedCourses(region_code, region_long_name);
    },
    getStateGeoCourses: function(position){
        var is_mobile_screen = true;
        if (screen.width >= 992) {
            is_mobile_screen = false;
        }

        var data = {
            'action': 'carousel_courses',
            'is_mobile_screen': is_mobile_screen,
            'coords_latitude': position.coords.latitude,
            'coords_longitude': position.coords.longitude
        };

        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";

        $.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: data,})
        .done(function (data) {
            // Create Cookie UF
            home_script.createCookieUf('selectedUf', data.short_name);
            home_script.createCookieUf('selectedUfName', data.long_name);

            $(".blco-cursos .pbox-state #total").text(data.total);
            $(".blco-cursos .box-geolocation .state-select").find("option[value='"+ data.short_name +"']").attr('selected', true);
            $(".blco-cursos .pbox-state .btn-state").text(data.long_name +" ("+ data.short_name +")");
            $('.blco-cursos .container .row .carousel .carousel-inner').html(data.boxCourses);

            home_script.destroySwiper(swiperCarouselCourses);
            swiperCarouselCourses = home_script.initSwiper("#swiper-carousel-courses");
        });
    },
    getStateSelectedCourses: function(region_code, region_long_name){
        var is_mobile_screen = true;
        if (screen.width >= 992) {
            is_mobile_screen = false;
        }

        var data = {
            'action': 'carousel_courses',
            'is_mobile_screen': is_mobile_screen,
            'region_code': region_code,
            'region_long_name': region_long_name
        };

        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";

        $.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: data,})
        .done(function (data) {
            // Create Cookie UF
            home_script.createCookieUf('selectedUf', data.short_name);
            home_script.createCookieUf('selectedUfName', region_long_name);

            $(".blco-cursos .pbox-state #total").text(data.total);
            $(".blco-cursos .box-geolocation .state-select").find("option[value='"+ data.short_name +"']").attr('selected', true);
            $(".blco-cursos .pbox-state .btn-state").text(region_long_name +" ("+ region_code +")");
            $('.blco-cursos .container .row .carousel .carousel-inner').html(data.boxCourses);

            home_script.destroySwiper(swiperCarouselCourses);
            swiperCarouselCourses = home_script.initSwiper("#swiper-carousel-courses");
        });
    },
	getStateGeoIes: function(position){
        var is_mobile_screen = true;
        if (screen.width >= 992) {
            is_mobile_screen = false;
        }

        var data = {
            'action': 'carousel_universidades',
            'is_mobile_screen': is_mobile_screen,
            'coords_latitude': position.coords.latitude,
            'coords_longitude': position.coords.longitude
        };

        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";

        $.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: data,})
        .done(function (data) {
            // Create Cookie UF
            home_script.createCookieUf('selectedUf', data.short_name);
            home_script.createCookieUf('selectedUfName', data.long_name);

            $(".blco-universidades .pbox-state #total").text(data.total);
            $(".blco-universidades .box-geolocation .state-select").find("option[value='"+ data.short_name +"']").attr('selected', true);
            $(".blco-universidades .pbox-state .btn-state").text(data.long_name +" ("+ data.short_name +")");
            $('.blco-universidades .container .row .carousel .carousel-inner').html(data.imageItem);

            home_script.destroySwiper(swiperCarouselUnivs);
            swiperCarouselUnivs = home_script.initSwiper("#swiper-carousel-unvs");
        });
    },
    getStateSelectedIes: function(region_code, region_long_name){
        var is_mobile_screen = true;
        if (screen.width >= 992) {
            is_mobile_screen = false;
        }

        var data = {
            'action': 'carousel_universidades',
            'is_mobile_screen': is_mobile_screen,
            'region_code': region_code,
            'region_long_name': region_long_name
        };

        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";
        $.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: data,})
        .done(function (data) {
            // Create Cookie UF
            home_script.createCookieUf('selectedUf', data.short_name);
            home_script.createCookieUf('selectedUfName', region_long_name);

            $(".blco-universidades .pbox-state #total").text(data.total);
            $(".blco-universidades .box-geolocation .state-select").find("option[value='"+ data.short_name +"']").attr('selected', true);
            $(".blco-universidades .pbox-state .btn-state").text(region_long_name +" ("+ region_code +")");
            $('.blco-universidades .container .row .carousel .carousel-inner').html(data.imageItem);

            home_script.destroySwiper(swiperCarouselUnivs);
            swiperCarouselUnivs = home_script.initSwiper("#swiper-carousel-unvs");
        });
    },
	initGeoLocation: function(){
        $(document).click(function(e){
            var externo = !$(e.target).closest(".box-geolocation")[0];
            if (externo && !$(e.target).hasClass("btn-state")){
                $(".box-geolocation").removeClass("show");
            }
        });

        $(document).click(function(e){
            var externo = !$(e.target).closest(".boxBranco")[0];
            if (externo || $(e.target).hasClass("close")){
                $(".modal-parcelas").hide();
            }
        });

        $(".bt-modal-simulacao").click(function(){
            $('.modal-parcelas').show();
            return false;
        });

        $(".pbox-state .btn-state").click(function(){
            $(this).parents(".geo-states").find(".box-geolocation").addClass("show");
        });

        $(".site-content .box-geolocation .btn-target").click(function(){
            $(this).parents(".geo-states").find(".box-geolocation").removeClass("show");

            if ($(this).parents(".geo-states").data("type") == "ies")
                navigator.geolocation.getCurrentPosition( home_script.getStateGeoIes );
            else
                navigator.geolocation.getCurrentPosition( home_script.getStateGeoCourses )
        });

        $(".site-content .box-geolocation .state-select").change(function(){
            $(this).parents(".geo-states").find(".box-geolocation").removeClass("show");

            if ($(this).parents(".geo-states").data("type") == "ies")
                home_script.getStateSelectedIes($(this).val(), $(this).find('option:selected').text());
            else
                home_script.getStateSelectedCourses($(this).val(), $(this).find('option:selected').text());
        });
    },
    createCookieUf: function(name, value){
        const d = new Date();
        d.setTime(d.getTime() + (1*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    },
    getCookie(name) {
        let decodedCookie = decodeURIComponent(document.cookie);
        let listCookies = decodedCookie.split(';');
        let cookiesWithoutSpace;

        cookiesWithoutSpace = listCookies.map(((cookie) => {
            return cookie.trim().split('=')
        })).filter(((c) => c[0] === name))

        if (cookiesWithoutSpace.length == 0)
            return "";
        return cookiesWithoutSpace[0][1];
    },
    initSwiper: function(swiperId) {

        let swiperOptions = {
            breakpoints: {
                300: {
                    slidesPerView: "auto",
                    spaceBetween: 0
                },
                765: {
                    slidesPerView: 4,
                    spaceBetween: 1,
                    slidesPerGroup: 4,
                    loopFillGroupWithBlank: true
                }
            },
            pagination: {el: ".swiper-pagination", clickable: true},
            preLoadImages: true,
            lazy: false
        };


        if (swiperId == '#swiper-carousel-unvs') {
            swiperOptions.preLoadImages = false;
            swiperOptions.lazy = {enabled: true, loadOnTransitionStart: true};

            return new Swiper(swiperId, swiperOptions);
        } else {

            return new Swiper(swiperId, swiperOptions);
        }
    },
    destroySwiper: function(data){
        if (typeof data !== "undefined") {
            data.destroy(false, true);
        }
    },
    mapMediaSwiper: function(){
        $(document).ready(function() {

            window.addEventListener("resize", () => home_script.checkQuerySwiper(), true);

            home_script.checkQuerySwiper();
        });
    },
    checkQuerySwiper: function(configs){
        let w = window.innerWidth;
        let page = document.getElementById("page")
        let last_w = page.getAttribute("data-query");

        if(w < 768){
            if(last_w != "mobile") {
                swiperVantagem = home_script.initSwiper("#swiper-container");
                swiperHistory = home_script.initSwiper("#swiper-history");
                swiperDuvidas = home_script.initSwiper("#swiper-duvidas");
                swiperBlocoHeroAnos = home_script.initSwiper("#bloco-hero-anos");
                swiperBlocoHeroCards = home_script.initSwiper("#bloco-hero-cards");
                swiperPassosCadastro = home_script.initSwiper("#swiper-passos");
                swiperSobre = home_script.initSwiper("#swiper-sobre");

                page.setAttribute("data-query", "mobile");
            }
        }else{
            if(last_w != "desk") {
                home_script.destroySwiper(swiperVantagem);
                home_script.destroySwiper(swiperHistory);
                home_script.destroySwiper(swiperDuvidas);
                home_script.destroySwiper(swiperBlocoHeroAnos);
                home_script.destroySwiper(swiperBlocoHeroCards);
                home_script.destroySwiper(swiperPassosCadastro);
                home_script.destroySwiper(swiperSobre);

                page.setAttribute("data-query", "desk");
            }
        }
    }
}
page_scripts['home'] = home_script;


$(document).ready(function () {
  $("#uf-select").change((e) => {
    let selectUF = e.currentTarget.value;
    localStorage.setItem("UF", selectUF);
    $("#ies-list li").hide().removeClass("active");
    $("#ies-list li." + selectUF)
      .fadeIn()
      .addClass("active");
       paginacao("#ies-list", "#ies-list-pag", "#total-ies");
  });

  $("#curso-select").change((e) => {
    let selectUF = e.currentTarget.value;
    localStorage.setItem("UF", selectUF);
    $("#cursos-list li").hide().removeClass("active");
    $("#cursos-list li." + selectUF)
      .fadeIn()
      .addClass("active");
       paginacao("#cursos-list", "#cursos-list-pag", "#total-cursos");
  });
});