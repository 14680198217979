function initLGPDToaster() {
  let toaster = document.getElementById("toast-LGPD");
  toaster.style.display = "block";
  let bt_continue = toaster.getElementsByClassName("bt-continue")[0];
  bt_continue.addEventListener("click", setLGPDToasterCookie, false);

  if (window.innerWidth < 992 && $("#form-interesse").length !== 1) {
    $(".simulate-btn").css("display", "none");
  }

  $(".bottom-bar").css("display", "none");
}

function setLGPDToasterCookie() {
  let date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  document.cookie =
    "lgpd_accepted=true; expires=" + date.toUTCString() + "; path=/";
  exitLGPDToaster();
}

function getLGPDToasterCookie() {
  let str_cookie = "; " + document.cookie;
  let parts_cookie = str_cookie.split("; lgpd_accepted=");
  if (parts_cookie.length === 2) return parts_cookie.pop().split(";").shift();
  return false;
}

function exitLGPDToaster() {
  let bt_continue = document
    .getElementById("toast-LGPD")
    .getElementsByClassName("bt-continue")[0];
  bt_continue.removeEventListener("click", setLGPDToasterCookie, false);
  document.getElementById("toast-LGPD").className += " toast_exit";
  document.getElementById("toast-LGPD").style.display = "none";

  if (window.innerWidth > 991 && $("#form-interesse").length !== 1) {
    $(".bottom-bar").css("display", "block");
  }

  if (window.innerWidth < 992 && $("#form-interesse").length !== 1) {
    $(".simulate-btn").css("display", "block");
  }
}

$(document).ready(function () {
  if (getLGPDToasterCookie() != "true") {
    initLGPDToaster();
  }
});
