var unv_bolsa = {
	init: function() {

        if (url_site != null) {
            var ajaxurl = url_site + "/wp-admin/admin-ajax.php";
        }

        $(document).on({
            ajaxStart: function() { 
                $(".page-unv-bolsa .loading-box").css({'display' : 'block'}); 
                $(".page-unv-bolsa .loading-box .loading-spin").css({'display' : 'block'}); 
            },
             ajaxStop: function() { 
                $(".page-unv-bolsa .loading-box").css('display', 'none'); 
            }    
        });

        $(document).ready(function() {
            var title = $('.page-unv-bolsa').data('title');
            $('.form-unv-bolsa #input-ies').val(title);

            var termosUrl = $('.form-unv-bolsa').data('terms');
            $('.form-unv-bolsa .check01 label.group-label-pravaler').append('<a href="'+ termosUrl +'" class="txcolor-white" target="_blank" style="text-decoration:underline">Termos e Condições</a>');
            
            get_all_campus();
        });

        $('.form-unv-bolsa .step-1 #btn-next').click(function(event) {
            event.preventDefault();
            if (valida_form_step_1()) {
                $('.step-1').hide();
                $('.step-2').show();
            }
        });

        $('.form-unv-bolsa .step-2 #btn-previous').click(function(event) {
            event.preventDefault();
            $('.step-1').show();
            $('.step-2').hide();
        });

        $('.form-unv-bolsa #btn-veterano-previous').click(function(event) {
            event.preventDefault();
            $('.form-unv-bolsa .wpcf7').fadeIn('fast');
            $('.form-veterano').hide();
            $('.form-unv-bolsa div.custom-select-unv-bolsa div.select-items div:last-child').click();
        });

        $('.form-unv-bolsa input[type=submit]').click(function(event) { 
            if (!(valida_form_step_1() && valida_form_step_2())) {
                event.preventDefault();
            } else {
                $(".page-unv-bolsa .loading-box").css({'display' : 'block'});
                $(".page-unv-bolsa .loading-box .loading-spin").css({'display' : 'block'}); 
                var campus = $( "select.campus option:selected" ).text();
                var cursos = $( "select.cursos option:selected" ).text();
                var turnos = $( "select.turnos option:selected" ).text();
                $( "select.campus option:selected" ).val(campus);
                $( "select.cursos option:selected" ).val(cursos);
                $( "select.turnos option:selected" ).val(turnos);
            }
        });

        $('.form-unv-bolsa .wpcf7').submit(function() {
            $('.form-unv-bolsa #btn-submit').attr('disabled', 'disabled');
            $('.form-unv-bolsa input, .form-unv-bolsa select').each(function() {
                $(this).parent().addClass('disabled');
                $(this).attr('readonly', 'true');
            });
            $('#btn-previous').css('visibility', 'hidden');
        });

        if($('.screen-reader-response').html() != "" || $('.wpcf7-response-output').html()) {
            $('.form-unv-bolsa .wpcf7').hide();
            $('.form-success').fadeIn('fast');
        } else {
            $('.form-unv-bolsa .wpcf7').fadeIn('fast');
            $('.form-success').hide();
        }

        $('.check01 input').click(function() {
            if ($(this).is(':checked')) {
                $('.form-unv-bolsa #btn-submit').removeAttr('disabled');
            } else {
                $('.form-unv-bolsa #btn-submit').attr('disabled', 'disabled');
            }
        });

        $('.group-pravaler input').keypress(function(event) {
            if($(this).parent().hasClass('only_num')){
                var charCode = (event.which) ? event.which : event.keyCode
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                    return false;
            }
            return true;
        });

        $('body').on('click', '.form-unv-bolsa div.custom-select-unv-bolsa div', function() {
            $(this).parent().parent().removeClass('group-error');
        });

        $('body').on('click', '.form-unv-bolsa div.custom-select-unv-bolsa div.select-items div', function() {
            if ($(this).html() == 'SOU VETERANO') {
                $('.form-veterano').fadeIn('fast');
                $('.form-unv-bolsa .wpcf7').hide();
            }
        });

        $('body').on('click', '.form-unv-bolsa div.select-campus .select-items div', function() {
            var campus_id = $('.form-unv-bolsa div.select-campus select').val();
            var data = {
                'action': 'load_cursos_by_campus',
                'campus_id': campus_id,
            };
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                dataType: 'json',
                data: data,
            })
            .done(function (response) {
                build_options_li($('.form-unv-bolsa select.cursos'), response);
            });
        });

        $('body').on('click', '.form-unv-bolsa div.select-cursos .select-items div', function() {
            var curso_id = $('.form-unv-bolsa div.select-cursos select').val();
            var data = {
                'action': 'load_turnos_by_curso',
                'curso_id': curso_id,
            };
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                dataType: 'json',
                data: data,
            })
            .done(function (response) {
                build_options_li($('.form-unv-bolsa select.turnos'), response);
            });
        });

        function get_all_campus() {
            var slug = $('.page-unv-bolsa').data('slug');
            var data = {
                'action': 'load_campus_for_universidade',
                'universidade_slug': slug
            };
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                dataType: 'json',
                global: false,
                data: data,
            })
            .done(function (response) {
                build_options_li($('.form-unv-bolsa select.campus'), response);
            });
        }

        function build_options_li($element, json) {
            keys = Object.keys(json);
            
            var html_options = keys.map(function(data){
                return '<option value="' + data + '">' + json[data] + '</option>';   
            }).join("");

            $element.find('option').not(':first').remove();
            $element.append(html_options);

            $element.parent().find('.select-items').find('div').remove();
            for (j = 1; j < $element[0].length; j++) {
                var c = addEventClickWhichDivInSelect($element[0]);
                $element.parent().find('.select-items').append(c);
            }
        }

        function valida_form_step_1() {
            var retorno = true;
            $('.form-unv-bolsa .step-1 input, .form-unv-bolsa .step-1 select').each(function() {
                var value = $(this).val();

                if ($(this).parent().hasClass('required') && value.replace(/\s/g, '') === '') {
                    $(this).parent().addClass('group-error');
                    retorno = false;
                } else {
                    var message = '';
                    if ($(this).attr('name') == 'cpf' && !isCPF(value)) {
                        message = 'CPF inválido';
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'nome' && value.split(' ').length <= 1) {
                        message = 'Nome inválido, insira sobrenome';
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'telefone' && !isTel(value)) {
                        message = 'Telefone inválido';
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'email' && !isEmail(value)) {
                        message = 'E-mail inválido';
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'cep' && value.length < 8) {
                        message = 'CEP inválido';
                        retorno = false;
                    }
                    $(this).parent().parent().find('span.message-error').remove();
                    $(this).parent().removeClass('mb-0');
                    if (message) {
                        $(this).parent().addClass('group-error mb-0');
                        $(this).parent().parent().append('<span class="tx-body-s txcolor-white pl-1 txcolor-danger-text message-error">'+ message +'</span>');
                    }
                }
            });
            return retorno;
        }

        function valida_form_step_2() {
            var retorno = true;
            $('.form-unv-bolsa .step-2 input, .form-unv-bolsa .step-2 select').each(function() {
                var value = $(this).val();
                if ($(this).parent().hasClass('required') && (value == null || value.replace(/\s/g, '') === '')) {
                    $(this).parent().addClass('group-error');
                    retorno = false;
                }
            });
            return retorno;
        }

        function isEmail(e){
            var r = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return r.test(e);
        }

        function isTel(t){
            if (t.length < 10 || t.length > 11) return false;
            return true;
        }
    
        function isCPF(f){
            var s=0,r;
            if(f=="00000000000") return false;
            for(i=1;i<=9;i++)s=s+parseInt(f.substring(i-1,i))*(11-i);
            r=(s*10)%11;
            if((r==10)||(r==11)) r=0;
            if(r!=parseInt(f.substring(9,10))) return false;
            s=0;
            for(i=1;i<=10;i++)s=s+parseInt(f.substring(i-1,i))*(12-i);
            r=(s*10)%11;
            if((r==10)||(r==11))r=0;
            if(r!=parseInt(f.substring(10, 11))) return false;
            return true;
        }

        var x, i, j, selElmnt, a, b, c;
        /*look for any elements with the class "custom-select-unv-bolsa":*/
        x = document.getElementsByClassName("custom-select-unv-bolsa");
        for (i = 0; i < x.length; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            /*for each element, create a new DIV that will act as the selected item:*/
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            /*for each element, create a new DIV that will contain the option list:*/
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");
            for (j = 1; j < selElmnt.length; j++) {
                c = addEventClickWhichDivInSelect(selElmnt);
                b.appendChild(c);
            }
            x[i].appendChild(b);
            a.addEventListener("click", function(e) {
                /*when the select box is clicked, close any other select boxes,
                and open/close the current select box:*/
                e.stopPropagation();
                closeAllSelect(this);
                if (!this.parentNode.classList.contains('disabled')) {
                    this.nextSibling.classList.toggle("select-hide");
                    this.classList.toggle("select-arrow-active");
                }
            });
        }

        function addEventClickWhichDivInSelect(selElmnt) {
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            c.addEventListener("click", function(e) {
                /*when an item is clicked, update the original select box,
                and the selected item:*/
                var y, i, k, s, h;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                h = this.parentNode.previousSibling;
                for (i = 0; i < s.length; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        for (k = 0; k < y.length; k++) {
                            y[k].removeAttribute("class");
                        }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
                h.click();
            });
            return c;
        }
        function closeAllSelect(elmnt) {
            /*function that will close all select boxes in the document,
            except the current select box:*/
            var x, y, i, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            for (i = 0; i < y.length; i++) {
                if (elmnt == y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < x.length; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }
        /*if the user clicks anywhere outside the select box,
        then close all select boxes:*/
        document.addEventListener("click", closeAllSelect);
    }    
}
page_scripts['page-unv-bolsa'] = unv_bolsa;