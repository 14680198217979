/*******************************
 * Validacao form indique-uma-ies
 *******************************/
var form_indique_uma_ies = (function form_indique_uma_ies() {
    
    function initialize() {
        $('section.indique-uma-ies form input[type="submit"]').on('click', function (event) {
            console.log('SUBMIT NOVO');
            
            if (valida_form( $(this).parents('form') )) {
                send_ajax( $(this).parents('form') );
            }

            return false;
        });

        $('section.indique-uma-ies form input').on('keypress', function() {
            $(this).parent().parent().find('span.error').removeClass('error');
        });

        $('#modal-indique-uma-ies .close').on('click', function(){
            $('#modal-indique-uma-ies').fadeOut();
            $('body').css('overflow-y', 'auto');
        });

        $('button.modal-indique-uma-ies').on('click', function(){
            $('#modal-indique-uma-ies').fadeIn();
            $('body').css('overflow-y', 'hidden');
        });
    }

    function add_error_input($form, input_name) {
        $form.find('.error-all').show();
   
        var $input = $form.find('input[name="'+ input_name +'"]');
        $input.parent().addClass('group-error');
        $input.parent().parent().find('label').addClass('error');
        $input.parent().parent().find('span').addClass('error');
    }

    function valida_form($form) {
        var str;
        var retorno = true;

        // Validando input nome
        str = $form.find('input[name="nome"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "nome", 'Campo obrigatório');
            retorno = false;
        }

        // Validando input email 
        str = $form.find('input[name="email"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "email", 'Campo obrigatório');
            retorno = false;
        } else if (!isEmail(str)) {
            add_error_input($form, "email", 'E-mail incorreto');
            retorno = false;
        }

        // Validando input curso
        str = $form.find('input[name="curso"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "curso", 'Campo obrigatório');
            retorno = false;
        }

        // Validando input ies
        str = $form.find('input[name="ies"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "ies", 'Campo obrigatório');
            retorno = false;
        }
        
        return retorno;
    }

    function send_ajax($form) {
        const URL_AJAX = "/wp-admin/admin-ajax.php";

        jQuery.post(URL_AJAX, $form.serialize() + "&action=send_form_to_customer_indicated_a_ies", function(data, status) {}, 'json')
                .always(function(response) {
                    console.log(response);
                    if (response.status == 200) {
                        $form.parents('.indique-uma-ies').find('.main-img').html('<img src="/wp-content/themes/rebrand-2020/inc/assets/img/form-indique-uma-ies/bg-success.svg" alt="Indique uma IES logo abaixo." />');
                        $form.parents('.indique-uma-ies').find('.title h4').html('Informações enviadas com sucesso.');
                        $form.parents('.indique-uma-ies').find('.title h4').addClass('success');
                        $form.parents('.indique-uma-ies').find('.title p').html('Obrigado por compartilhar seu interesse. Nossa equipe vai analisar a possibilidade de incluir sua sugestão no nosso site.');

                        $([document.documentElement, document.body]).animate({
                            scrollTop: $form.parents('.indique-uma-ies').offset().top - 100
                        }, 500);

                        $form.remove(); // LIMPA FORMULÁRIO E SEUS DADOS
                    } else {
                        $form.find('.error-all').html('Erro no envio das informações, code: ' + response.status);
                        $form.find('.error-all').show();
                    }
                });       
    }

    return {"init": initialize};

})().init();