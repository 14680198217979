// no-undefined

var paged_crs = 1;
var val_filter_crs = '';
$form_filter_crs = $('#graduacao #filter-search');
$filter_unv = $('#universidades #filter-search');
$filter_state = $('.form-pesquisa-ies-crs #select-estado');
$filter_city = $('.form-pesquisa-ies-crs #select-cidade');

/*******************************
 * Requests Ajax - buttons(Load More)
 *******************************/
var requestsHTML = (function () {

    function initialize() {
        load_posts_page_404();
        load_articles_blog_by_ajax();
        load_cursos_by_loadmore();
        load_cursos_by_search();
        load_cursos_by_filters();
        load_unv_by_loadmore();
        load_unv_by_search_filter();

        $(document).on({
            ajaxStart: function(event) {
                // verifica se o ajax foi requisitado atraves do botão Carregar Mais ou não
                if(event.target.activeElement.id == 'input-digite-pesquisar' || event.target.activeElement.id == 'bt-submit-digite-pesquisar'){
                    // mostra o loading no resultado de Universidades
                    $('.page-universidades .bloco02 .grade-universidades .loading-result').css({'display':'block','margin-bottom':'50px'});
                    //mostra o loading no resultado de Cursos
                    $('.page-cursos .container #resultados-busca .loading-result').css({'display':'block','margin-bottom':'50px'});
                    // limpa a grade de resultados de Universidades
                    $('.grade-universidades section').css('display', 'none');
                    // limpa a grade de resultados de Cursos
                    $('.page-cursos .container #resultados-busca #append-resultados-crs article').css('display', 'none');
                    // esconde o botão Carregar Mais e o contador de elementos por página
                    $('.section-carregar').hide();
                    // esconde o paragrafo que informa que nenhum resultado foi encontrado
                    $('.page-cursos .container #resultados-busca #append-resultados-crs p').css('display', 'none');
                } else if(event.target.activeElement.id == 'loadmore') {
                    // mostra o loading acima do botão ao clicar no botão Carregar Mais
                    $('.section-carregar .loading-result').css({'display':'block','margin-bottom':'50px'});
                }
            },
             ajaxStop: function(event) {
                 // verifica se o ajax foi requisitado atraves do botão Carregar Mais ou não
                if(event.target.activeElement.id == 'input-digite-pesquisar' || event.target.activeElement.id == 'bt-submit-digite-pesquisar'){
                    // esconde o loading no resultado de Universidades
                    $(".page-universidades .bloco02 .grade-universidades .loading-result").css({'display':'none','margin-bottom':'0px'});  
                    //mostra o loading no resultado de Cursos
                    $(".page-cursos .container #resultados-busca .loading-result").css({'display':'none','margin-bottom':'0px'});
                    // mostra a grade de resultados de Universidades
                    $('.grade-universidades section').css('display', 'block');
                    // mostra a grade de resultados de Cursos
                    $('.page-cursos .container #resultados-busca #append-resultados-crs article').css('display', 'block');
                    // mostra o botão Carregar Mais e o contador de elementos por página
                    $('.section-carregar').show();
                    $('.section-carregar .loading-result').css({'display':'none'});
                } else if(event.target.activeElement.id == 'loadmore'){
                    // esconde o loading acima do botão ao clicar no botão Carregar Mais
                    $('.section-carregar .loading-result').css({'display':'none','margin-bottom':'50px'});
                }
            }
        });
    }

    function load_cursos_by_search(){
        $('#graduacao #filter-search').find('button').on('click', function () {
            val_filter_crs = $form_filter_crs.find('input').val();
            
            if (val_filter_crs == '') {
                $form_filter_crs.find('input~span').text('Ops! Por favor digite algo para fazer a pesquisa :)');
            } else {
                paginate(val_filter_crs);
            }
            return false;
        });
    }

    function load_cursos_by_filters(){
        $('.page-cursos .filters').find('select').on('change', function () {
            val_filter_crs = $form_filter_crs.find('input').val();
    
            paginate(val_filter_crs);
    
            return false;
        });
    }
    
    function paginate(s) {
        
        var filter_ead = false;
        if($('.page-cursos .filters select option:selected[value="ead"]')[0] != undefined)
        filter_ead = true;
        
        paged_crs = 1;
        var limpar_resultado = true;
        
        if (s == '') {
            filter_category_crs(null, $form_filter_crs.data('termid'), '#append-resultados-crs', filter_ead, paged_crs, limpar_resultado);
        } else {
            filter_category_crs(s, $form_filter_crs.data('termid'), '#append-resultados-crs', filter_ead, paged_crs, limpar_resultado);
        }
    }
    
    function filter_category_crs(s, termid, resultado, filter_ead, paged_crs, limpar_resultado) {
        var $resultadoBusca = $(resultado);
        var ajax_cursos = false;

        // Animation loading spin
        $('.section-carregar div').not('.loading-result').show();

        if (s === null) {
            ajax_cursos = {
                'action': 'load_posts_by_ajax_categoria_crs',
                'paged': paged_crs,
                'term_id': termid,
                'filter_ead': filter_ead,
                'security': securityAjax
            };
        } else {
            ajax_cursos = {
                'action': 'load_posts_by_ajax_categoria_crs',
                'paged': paged_crs,
                's': s,
                'term_id': termid,
                'filter_ead': filter_ead,
                'security': securityAjax
            };
        }

        $.ajax({
            url: ajaxurl,
            type: 'POST',
            dataType: 'json',
            data: ajax_cursos
        }).done(function (response) {
            $('.section-carregar div').show();
            $('.bloco2-desk section.indique-uma-ies.filter-search').hide();

            if(typeof response === 'string')
                response = JSON.parse(response);

            if(limpar_resultado)
                $resultadoBusca.html('');

            var res_cursos = response['cursos'];

            if(res_cursos.length == 0){
                $('#loadmore').hide();
                $('.section-carregar div').hide();
                $('.bloco2-desk section.indique-uma-ies.filter-search').show();
            
                return
            }

            $(response['cursos']).each(function(key, data){
                var turnos = '';
                if(data['turnos_string'] != '' && data['turnos_string'] != null){
                    turnos = '<h4 class="tx-body-s">Turnos</h4>' +
                    data['turnos'].map(function(t){
                        return '<h5 class="tx-body-s m-0">' + t + '</h5>';
                    }).toString().replace(/,/g, '');
                }
                var cursos = $('.card-curso-graduacao').first().clone();
                cursos
                    .find('article')
                    .attr({
                        id: 'post' + data['id'],
                        class: cursos.find('article').attr('class') + ' ' + data['classes']
                    })
                cursos
                    .find('img')
                    .attr({
                        src: data['img_unv'],
                        alt: data['alt_unv']
                    })
                cursos
                    .find('header h3:eq(0) a')
                    .attr({
                        href: data['permalink'],
                        title: data['titulacao']
                    })
                    .text(data['titulacao']);
                cursos
                    .find('header h3:eq(1)')
                    .text(data['title'])
                cursos
                    .find('.turnos')
                    .empty()
                    .append(turnos)
                cursos
                    .find('a:eq(1)')
                    .attr({
                        href: data['permalink'],
                        title: data['title']
                    });
                $resultadoBusca.append(cursos);
            });
            
            $('.section-carregar .loading-result').hide();
            $('.section-carregar #total_show').html($resultadoBusca.find('article').length);
            $('.section-carregar #total').html(response['contador']);
            
            if ($resultadoBusca.find('article').length == response['contador']) {
                $('#loadmore').hide();
            } else {
                $('#loadmore').show();
            }
        });
    }
        
    function load_cursos_by_loadmore() {
        $('.page-cursos #loadmore').on('click', function () {
            var limpar_resultado = false;
            var $resultadoBusca = $('.page-cursos #append-resultados-crs');
            var filter_ead = false;
            if ($('.page-cursos .filters select option:selected[value="ead"]')[0] != undefined)
                filter_ead = true;
            
            paged_crs++;

            if (val_filter_crs == '') {
                filter_category_crs(null, $resultadoBusca.data('termid'), '#append-resultados-crs', filter_ead, paged_crs, limpar_resultado);
            } else {
                filter_category_crs(val_filter_crs, $resultadoBusca.data('termid'), '#append-resultados-crs', filter_ead, paged_crs, limpar_resultado);
            }
            
            return false;
        });
    }

    function load_unv_by_loadmore() {
        $('.page-universidades #loadmore').on('click', function () {
            var limpar_grade_unv = false;
            paged_crs++;
            
            filter_category_unv($filter_unv.find('input').val(), '#append-resultados-unv', paged_crs, limpar_grade_unv);
            return false;
        });
    }

    function load_unv_by_search_filter() {

        $filter_unv.find('button').on('click', function () {
            var limpar_grade_unv = true;
            paged_crs = 1;
            if ($filter_unv.find('input').val() == '') {
                $filter_unv.find('input~span').text('Ops! Por favor digite algo para fazer a pesquisa :)');
            } else {
                filter_category_unv($filter_unv.find('input').val(), '#append-resultados-unv', paged_crs, limpar_grade_unv);
            }
            return false;
        });
    
        $('.localidade .bt-filtro-localidade').on('click', function() {
            var limpar_grade_unv = true;
            paged_crs = 1;
            if ($filter_unv.find('input').val() == '') {
                $filter_unv.find('input~span').text('Ops! Por favor digite algo para fazer a pesquisa :)');
            } else {
                filter_category_unv($filter_unv.find('input').val(), '#append-resultados-unv', paged_crs, limpar_grade_unv);
            }
            return false;
        });
    }

    function filter_category_unv(s, resultado, paged_crs, limpar_grade_unv) {
        var $resultadoBuscaLocal = $(resultado);
        
        var data = {
            'action': 'load_unv_filter',
            's': s,
            'paged': paged_crs,
            'security': securityAjax
        };
        $.ajax({
            url: ajaxurl,
            type: 'POST',
            dataType: 'json',
            data: data,
        }).done(function (data) { 
                if (data.status == true) {
                    $('.bloco02 section.indique-uma-ies.filter-search').hide();
                    $('.section-carregar .col-12').show();

                    clones = [];
                    $.each(data.unvs, function (index, val) {
                        var $clone = $('.bloco02 .card-unv-categoria').first().clone()
                        $clone
                            .attr('id', 'post-' + val.term_id);
                        $clone
                            .find('a')
                            .attr({
                                href: val.url,
                                title: val.name
                            });
                        $clone
                            .find('img')
                            .attr({
                                src: val.img,
                                alt: val.name
                            });
                        $clone.find('h3')
                            .text(val.name);
                        $clone.css('display', 'block');
                        clones.push($clone);
                    });

                    if(limpar_grade_unv)
                        $resultadoBuscaLocal.empty();

                    $resultadoBuscaLocal.append(clones);
                    $('.section-carregar').show();
                    $('.section-carregar #total_show').html($resultadoBuscaLocal.find('section').length);
                    $('.section-carregar #total').html(data.contador);
                    
                    if ($resultadoBuscaLocal.find('section').length == data.contador) {
                        $('#loadmore').hide();
                    } else {
                        $('#loadmore').show();
                    }
                } else {
                    $resultadoBuscaLocal.html('');
                    $('.bloco02 section.indique-uma-ies.filter-search').show();

                    $('.section-carregar #total_show, .section-carregar #total').html(0);
                    $('.section-carregar .col-12').hide();
                    $('#loadmore').hide();
                }
            });
    }

    function load_articles_blog_by_ajax() {
        $('.page-mapa-site .articles-map #loadmore').on('click', function () {
            var data = {
                'action': 'load_articles_blog_by_ajax',
                'security': securityAjax,
                'paged': ($(this).data('paged') + 1)
            };

            $.post(ajaxurl, data, function (response) {
                $('.articles-map ul').append(response);
                $('.page-mapa-site .articles-map #loadmore').data('paged', ($('.page-mapa-site .articles-map #loadmore').data('paged') + 1));

                $('.articles-map .section-carregar #total_show').html($('.articles-map ul li').length);
                if ($('.articles-map ul li').length == $('.articles-map #total').html()) {
                    $('.page-mapa-site .articles-map #loadmore').remove();
                }
            });

            return false;
        });
    }

    function load_posts_page_404() {
        var $btnLoadmore = $('.page-error-404 .cursos #loadmore');

        $btnLoadmore.on('click', function () {
            var $blockResult = $('.cursos .block-result');
            var data = {
                'action': 'load_posts_page_404',
                'security': securityAjax,
                'paged': ($(this).data('paged') + 1)
            };

            $.post(ajaxurl, data, function (response) {
                $blockResult.append(response.html);
                $btnLoadmore.data('paged', ($btnLoadmore.data('paged') + 1));

                if ($blockResult.find('.col-md-4 a').length == response.qtdTotal) {
                    $btnLoadmore.remove();
                }
            });

            return false;
        });
    }

    return {
        "init": initialize
    }
})();
requestsHTML.init();