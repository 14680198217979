var tax_curso_single = {
  init: function() {
		$("#form-proposal").on("submit", async function (e) {
			e.preventDefault();

      /*global valida_form_interesse*/
      /*eslint no-undef: "error"*/
      if(valida_form_interesse(e)) 
			{
        /*global envia_api_interesse_redirect_cadastro*/
        /*eslint no-undef: "error"*/
				envia_api_interesse_redirect_cadastro()
			}
    });
  }
};
/*global page_scripts*/
/*eslint no-undef: "error"*/
page_scripts['curso-single'] = tax_curso_single;