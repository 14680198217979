var teste = (function () {
    var nomeAluno, emailAluno, valorMensalidade, quantMensalidade;
    var exp_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    function initialize() {
        $('#simulador-desconto20').on('submit', function () {

            if (!valida_campos($(this))) {
                return false;
            } else {
                calculo_bolsa(valorMensalidade); /* Bolsa desconto no primeiro semestre */
                calculo_bolsa_20(valorMensalidade, quantMensalidade); /* Sem Juros + 20% de desconto */
                calculo_bolsa_33(valorMensalidade, quantMensalidade); /* Menor Parcela + 20% de desconto com o 33,3 */
                calculo_ead(valorMensalidade, quantMensalidade);

                $('#simulacao-20-desconto').fadeIn();

                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#simulacao-20-desconto").offset().top - 60
                }, 1000);
            }
            return false;
        });

        $('#simulacao-20-desconto .preview button.btn-fluxo-pag').on('click', function () {
            $(this).parents('.cards').find('.preview').fadeOut("slow", function() {
                $(this).parents('.cards').find('.header .sub-title').fadeOut();
                $(this).parents('.cards').find('.info').fadeIn();
            });
        });
    }

    function formatar_moeda_real(valor) {
        valor = valor.toFixed(2);
        return valor.toLocaleString('pt-br', {minimumFractionDigits: 2});
    }

    function valida_campos($form) {
        var campos_validado = [];
        var nome = $form.find('#nomeAluno').val();
        if (nome.match(/^[A-Za-z\s]+$/)) {
            nomeAluno = nome;
        } else {
            $form.find('#nomeAluno').parent().addClass('group-error');
            campos_validado.push(false);
        }

        var email = $form.find('#emailAluno').val();
        if (email.match(exp_email)) {
            emailAluno = email;
        } else {
            $form.find('#emailAluno').parent().addClass('group-error');
            campos_validado.push(false);
        }

        var valor = $form.find('#valorMensalidade').val();
        valor = valor.replace(/[^\d\,]/g, '');
        valor = parseFloat(valor.replace(',', '.'));

        if (!isNaN(valor)) {
            valorMensalidade = valor;
        } else {
            $form.find('#valorMensalidade').parent().addClass('group-error');
            campos_validado.push(false);
        }

        var qtdMensalidades = $form.find('#quantMensalidade').val();
        if (qtdMensalidades.match(/^[1-9]\d*$/)) {
            quantMensalidade = qtdMensalidades * 2;
        } else {
            $form.find('#quantMensalidade').parent().addClass('group-error');
            campos_validado.push(false);
        }

        return campos_validado.indexOf(false);
    }

    /* Bolsa + 40% de desconto no primeiro semestre */
    function calculo_bolsa(valorMensalidade) {
        var calc_desconto = $('#simulacao-20-desconto .card-01 .preview .prev-valor').data('calcdesconto');
        var valor_com_desconto = valorMensalidade - (calc_desconto * valorMensalidade / 100);
        $('#simulacao-20-desconto .card-01 .preview .prev-meses').text('6');
        $('#simulacao-20-desconto .card-01 .sub-title span').text('R$ ' + formatar_moeda_real(valorMensalidade));
        $('#simulacao-20-desconto .card-01 .preview .prev-valor').text(formatar_moeda_real(valor_com_desconto));
        $('#simulacao-20-desconto .card-01 .info .valor').text('6x R$ ' + formatar_moeda_real(valor_com_desconto));
    }

    /* Sem Juros + 20% de desconto */
    function calculo_bolsa_20(valorMensalidade, quantMensalidade) {
        var calc_desconto = $('#simulacao-20-desconto .card-02 .preview .prev-valor').data('calcdesconto');
        var metade_mensalidade = 0.5 * valorMensalidade;
        var valor_com_desconto = metade_mensalidade - (calc_desconto * metade_mensalidade / 100);

        $('#simulacao-20-desconto .card-02 .sub-title span').text('R$ ' + formatar_moeda_real(valorMensalidade));
        $('#simulacao-20-desconto .card-02 .preview .prev-meses').text('12');
        $('#simulacao-20-desconto .card-02 .preview .prev-valor').text(formatar_moeda_real(valor_com_desconto));

        var str_html = '<div class="mt-0 mb-0 container d-flex justify-content-between text-center">\n' +
            '<span class="flex-fill">1° semestre</span >\n' +
            '<span class="flex-fill txcolor-primary font-weight-bold">12x R$ ' + formatar_moeda_real(valor_com_desconto) + '</span>\n' +
            '</div>';

        for (var i = 2; i <= quantMensalidade; i++) {
            str_html = str_html.concat('<div class="mt-0 mb-0 container d-flex justify-content-between text-center">\n' +
                '<span class="flex-fill">' + i + '° semestre</span>\n' +
                '<span class="flex-fill txcolor-primary font-weight-bold">' + 12 + 'x R$ ' + formatar_moeda_real(metade_mensalidade) + '</span>\n' +
                '</div>');
        }

        $('#simulacao-20-desconto .card-02 .info').html(str_html);
    }

    /* Menor Parcela + 20% de desconto com o 33,3 */
    function calculo_bolsa_33(valorMensalidade, quantMensalidade) {
        var calc_desconto = $('#simulacao-20-desconto .card-03 .preview .prev-valor').data('calcdesconto');
        var meses = 18;
        var mensalidade_com_33 = 0.333333 * valorMensalidade;
        var mensalidade_com_desconto = mensalidade_com_33 - (calc_desconto * mensalidade_com_33 / 100);

        $('#simulacao-20-desconto .card-03 .sub-title span').text('R$ ' + formatar_moeda_real(valorMensalidade));
        $('#simulacao-20-desconto .card-03 .preview .prev-meses').text(meses);
        $('#simulacao-20-desconto .card-03 .preview .prev-valor').text(formatar_moeda_real(mensalidade_com_desconto));

        var str_html = '<div class="mt-0 mb-0 container d-flex justify-content-between text-center">\n' +
            '<span class="flex-fill">1° semestre</span >\n' +
            '<span class="flex-fill txcolor-primary font-weight-bold">' + meses + 'x R$ ' + formatar_moeda_real(mensalidade_com_desconto) + '</span>\n' +
            '</div>';

        for (var i = 2; i <= quantMensalidade; i++) {

            switch (i) {
                case 2:
                    meses = 15;
                    mensalidade_com_desconto = (valorMensalidade * 40) / 100;
                    break;
                case 3:
                    meses = 12;
                    mensalidade_com_desconto = (valorMensalidade * 50) / 100;
                    break;
                case 4:
                    meses = 12;
                    mensalidade_com_desconto = (valorMensalidade * 50) / 100;
                    break;
                case 5:
                    meses = 15;
                    mensalidade_com_desconto = (valorMensalidade * 60) / 100;
                    break;
                case 6:
                    meses = 15;
                    mensalidade_com_desconto = (valorMensalidade * 65) / 100;
                    break;
                case 7:
                    meses = 18;
                    mensalidade_com_desconto = (valorMensalidade * 65) / 100;
                case 8:
                    meses = 18;
                    mensalidade_com_desconto = (valorMensalidade * 65) / 100;
                case 9:
                    meses = 18;
                    mensalidade_com_desconto = (valorMensalidade * 65) / 100;
                case 10:
                    meses = 18;
                    mensalidade_com_desconto = (valorMensalidade * 65) / 100;
                    break;
            }

            str_html = str_html.concat('<div class="mt-0 mb-0 container d-flex justify-content-between text-center">\n' +
                '<span class="flex-fill">' + i + '° semestre</span>\n' +
                '<span class="flex-fill txcolor-primary font-weight-bold">' + meses + 'x R$ ' + formatar_moeda_real(mensalidade_com_desconto) + '</span>\n' +
                '</div>');
        }

        $('#simulacao-20-desconto .card-03 .info').html(str_html);
    }

    /* EAD */
    function calculo_ead(valorMensalidade, quantMensalidade) {
            var calc_desconto = $('#simulacao-20-desconto .card-04 .preview .prev-valor').data('calcdesconto');
            var meses = 10;
            var juros = 0.6;
            var mensalidade_60 = juros * valorMensalidade;
            var mensalidade_com_desconto = mensalidade_60 - (calc_desconto * mensalidade_60 / 100);

            $('#simulacao-20-desconto .card-04 .sub-title span').text('R$ ' + formatar_moeda_real(valorMensalidade));
            $('#simulacao-20-desconto .card-04 .preview .prev-meses').text(meses);
            $('#simulacao-20-desconto .card-04 .preview .prev-valor').text(formatar_moeda_real(mensalidade_com_desconto));

            var str_html = '<div class="mt-0 mb-0 container d-flex justify-content-between text-center">\n' +
                '<span class="flex-fill">1° semestre</span >\n' +
                '<span class="flex-fill txcolor-primary font-weight-bold">' + meses + 'x R$ ' + formatar_moeda_real(mensalidade_com_desconto) + '</span>\n' +
                '</div>';

            for (var i = 2; i <= quantMensalidade; i++) {

                switch (i) {
                    case 2:
                        mensalidade_semestre = mensalidade_com_desconto;
                        break;
                    case 3:
                        mensalidade_semestre = valorMensalidade * 0.6595;
                        break;
                    case 4:
                        mensalidade_semestre = valorMensalidade * 0.6769;
                        break;
                    case 5:
                        mensalidade_semestre = valorMensalidade * 0.6948;
                        break;
                    case 6:
                        mensalidade_semestre = valorMensalidade * 0.7132;
                        break;
                    case 7:
                        mensalidade_semestre = valorMensalidade * 0.7321;
                        break;
                    case 8:
                        mensalidade_semestre = valorMensalidade * 0.7515;
                        break;
                    case 9:
                        mensalidade_semestre = valorMensalidade * 0.7793;
                        break;
                    case 10:
                        mensalidade_semestre = valorMensalidade * 0.7983;
                        break;
                }
    
                str_html = str_html.concat('<div class="mt-0 mb-0 container d-flex justify-content-between text-center">\n' +
                    '<span class="flex-fill">' + i + '° semestre</span>\n' +
                    '<span class="flex-fill txcolor-primary font-weight-bold">' + meses + 'x R$ ' + formatar_moeda_real(mensalidade_semestre) + '</span>\n' +
                    '</div>');
            }
    
            $('#simulacao-20-desconto .card-04 .info').html(str_html);
    }

    return {
        init: initialize
    };
})().init();