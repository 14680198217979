(function(){

    $('.o3c_header, section#primary.page-ajuda article.blc-icones div.help_chat, #content section#primary.page-covid19 main#main.site-main article.blc-perguntas label.chat').on('click', function(e) {
        e.preventDefault();
        $chat_body = $('.o3c_body');
        $iframe = $chat_body.find('iframe#body-frame');
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({'open': 'open-chat'});
        }
        if(!$chat_body.hasClass('open-animation')){
            $chat_body.addClass('open-animation');
            if (typeof $iframe.attr('src') === 'undefined') {
                $iframe.attr('src', $iframe.attr('data-src'));
            }
        } else {
            $chat_body.removeClass('open-animation');
        }
    });
}());
