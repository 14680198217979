var $ = jQuery.noConflict();
if (url_site != null) {
    var ajaxurl = url_site + "/wp-admin/admin-ajax.php";
}
var url_site;
var page_scripts = {};

var search = (function () {

    function initialize() {
      var timeout = null;
      jQuery("form#search .no-result").css("display", "none");
      $("#search .search-result").hide();
      $("#search #field").keyup(function (e) {
        let len = e.target.value;
        if (len.length >= 3) {
          $(".links-populares").fadeOut();
          $(".search-result").fadeIn();
          clearTimeout(timeout);
          timeout = setTimeout(function () {
            jQuery(
              "form#search .suggested-result section"
            ).css("display", "none");
            jQuery("form#search .no-result").css("display", "none");
            if (jQuery("#search").find("#field").val()) {
              search_main(jQuery("#search"));
            }
          }, 400);
        } else {
          $(".links-populares").fadeIn();
          $(".search-result").fadeOut();
        }
      });

      jQuery('#search input:checkbox[name="filter[]"]').click(function () {
        if ($(this).val() == "all" && $(this).is(":checked")) {
          jQuery('#search input:checkbox[name="filter[]"]').each(function () {
            if ($(this).attr("id") != "filter-all" && !$(this).is(":checked")) {
              $(this).trigger("click");
            }
          });
        }
        jQuery("form#search .no-result").css("display", "none");
        if (jQuery("#search").find("#field").val()) {
          search_main(jQuery("#search"));
        }
      });

      // Create a media condition that targets viewports at least 768px wide
      const mediaQuery = window.matchMedia("(min-width: 280px)" && "(max-width: 991px)");
      // Check if the media query is true
      if (mediaQuery.matches) {
        // Then trigger an alert
        $(".links-populares ul").hide();
        $(".links-populares p.subtitle").click( function() {
            $(this).toggleClass("active");
            $(this).next("ul").slideToggle();
        });
      }

      if (home_script.getCookie('selectedUf') != "") {     
          let region_code = home_script.getCookie('selectedUf');
          let region_long_name = home_script.getCookie('selectedUfName');
          $('form#search .box-geolocation .state-select').find('option[value="'+ region_code +'"]').attr('selected', true);
          $("form#search .pbox-state .btn-state").text(region_long_name +" ("+ region_code +")");
      }

      $(".pbox-state .btn-state").click(function(){
          $(this).parents(".geo-states").find(".box-geolocation").addClass("show");
      });

      $(".box-geolocation .btn-target").click(function(){
          jQuery("form#search .no-result").css("display", "none");
          $(this).parents(".geo-states").find(".box-geolocation").removeClass("show");
          navigator.geolocation.getCurrentPosition( search_geolocation_user );
      });

      $("form#search .box-geolocation .state-select").change(function(){
          jQuery("form#search .no-result").css("display", "none");
          $(this).parents(".geo-states").find(".box-geolocation").removeClass("show");
          select_state($(this).val(), $(this).find('option:selected').text());
          search_main(jQuery("#search"));
      });

      $(document).click(function(e){
        var externo = !$(e.target).closest(".box-geolocation")[0];
        if (externo && !$(e.target).hasClass("btn-state")){ 
            $(".box-geolocation").removeClass("show");
        }
      });
    }
    
    function select_state(region_code, region_long_name) {
      // Create Cookie UF
      if (region_code !== ""){
        home_script.createCookieUf('selectedUf', region_code);
        home_script.createCookieUf('selectedUfName', region_long_name);
        $("form#search .pbox-state .btn-state").text(region_long_name +" ("+ region_code +")");
      } else {
        $("form#search .pbox-state .btn-state").text("todos os estados");
      }
    }

    function search_geolocation_user(position) {              
      let data = { 
        'action': 'search_geolocation', 
        'coords_latitude': position.coords.latitude,
        'coords_longitude': position.coords.longitude
      };

      let getUrl = window.location;
      let baseUrl = getUrl .protocol + "//" + getUrl.host + "/";

      $.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: data,})
      .done(function (data) {
          // Create Cookie UF
          home_script.createCookieUf('selectedUf', data.region_code);
          home_script.createCookieUf('selectedUfName', data.region_long_name);

          $('form#search .box-geolocation .state-select').find('option[value="'+ data.region_code +'"]').attr('selected', true);
          $("form#search .pbox-state .btn-state").text(data.region_long_name +" ("+ data.region_code +")");
          search_main(jQuery("#search"));
      });
    }

    function search_main($targetSearch) {
        var filters = [];

        $targetSearch.find('input:checkbox[name="filter[]"]:checked').each(function (k, e) {
            filters.push(jQuery(this).val());
        });

        let state_code = $targetSearch.find('.box-geolocation .state-select').find(':selected').val();

         jQuery('form#search .suggested-result').hide(0,function(){
            jQuery('form#search .loading-result').show();
        });

        jQuery(function (jQuery) {
            var data = {
                'action': 'ajax_results_search',
                'security': securityAjax,
                's': $targetSearch.find('#field').val(),
                'filters': filters
            };

            if (state_code !== "") {
                data.state_code = state_code;
            }

            var search = jQuery.post(ajaxurl, data);
            search.always(function() {
                jQuery('form#search .loading-result').css('display', 'block');
            })
            search.fail(function() {
                jQuery('form#search .loading-result').css('display', 'none');
                jQuery('form#search .no-result').css('display', 'block');
            })
            search.done(function(response) {
                if (typeof response === 'string' ) {
                    response = JSON.parse(response);
                }
                var types = ['universidades', 'graduacao', 'post', 'page'];

                var keys = Object.keys(response);
                var hasResults = false;

                jQuery(keys).each(function (key, data) {
                    if (response[data].length) {
                        hasResults = true;
                    }
                });

                if (hasResults) {
                    for (var i = 0; i < types.length; i += 1) {
                        if (typeof response[types[i]] !== 'undefined' && response[types[i]].length) {
                            jQuery('#target-' + types[i]).find('.result-item').not(':first').remove();

                            var html = jQuery('#target-' + types[i]).find('a:eq(0)').detach();
                            var print = '';

                            jQuery(response[types[i]]).each(function (key, data) {
                                html.attr('href', data.guid);
                                html.find('.title').html(data.title);
                                if (data.image) {
                                    html.find('img').attr(
                                      {
                                        'alt': data.title,
                                        'src': data.image
                                      }
                                    );
                                }
                                print = print + '' + html[0].outerHTML;
                            });

                            jQuery('#target-' + types[i]).find('.result-item:eq(1)').remove();
                            jQuery('#target-' + types[i]).find('div:eq(0)').after(print);
                            jQuery('form#search .suggested-result section#target-' + types[i]).css('display', 'block');

                            var href = '/?s=' + jQuery('#search').find('#field').val() + '&filter[]=' + types[i];

                            if (state_code !== "") {
                                href + '&uf='+ state_code;
                            }
                            
                            if (response['count_' + types[i]] > 2) {
                                jQuery('form#search .suggested-result section#target-' + types[i]).find('.filter-see-more').css('display', 'block');
                                jQuery('form#search .suggested-result section#target-' + types[i]).find('.filter-see-more').attr('href', href);
                            } else {
                                jQuery('form#search .suggested-result section#target-' + types[i]).find('.filter-see-more').css('display', 'none');
                            }
                        } else {
                            jQuery('form#search .suggested-result section#target-' + types[i]).css('display', 'none');
                        }
                    }
                    // Btn all
                    var href = '/?s=' + jQuery('#search').find('#field').val() + '&filter[]=all';
             
                    if (state_code !== "") {
                        href + '&uf='+ state_code;
                    }

                    jQuery('form#search #show-all').attr('href', href);

                    jQuery('form#search .loading-result').hide(0, function(){
                        jQuery('form#search .suggested-result').show(0);
                    });
                } else {
                    jQuery('form#search .loading-result').hide(0);
                    jQuery('form#search .no-result').show(0);
                }
            });
        });
    }

    return {
        "init": initialize
    }
})();
search.init();


