!function(window){

    var $load_more_404 = document.querySelector('#loadmore_404');

    if(!$load_more_404) return;

    var $ = jQuery;

    var $total_404 = document.querySelector('#total_404');
    var $total_show_404 = document.querySelector('#total_show_404');

    var $section_new_courses = document.querySelector('.block-result .pagination-courses');
    
    var $section_load_more = document.querySelector('.block-result .section-carregar_404');
    var total_post = $section_load_more.getAttribute('data-total');
    var posts_per_page = parseInt($section_load_more.getAttribute('data-per-page'));
    var posts_showed = posts_per_page;
    var nextPage = 1;

    var loadMore = function(e) {
        e.preventDefault();

        var data = {
            'action': 'load_posts_page_404',
            'security': securityAjax,
            'paged': nextPage,
            'posts_per_page': posts_per_page,
        };

        $.post(ajaxurl, data, function (response) {

            if(!response.length) return

            var newCourses = response.map(function(course) {
                return '<div class="col-md-4 cursos">' +
                    '<a href="'+ course.link +'">' +
                       '<h3 class="tx-display-m txcolor-primary" title="Cursos de '+ course.name +'">Cursos de ' +
                            course.name +'</h3>' +
                        '<span>Ver todos os cursos <i class="icon-pravaler icon-arrow-circle-right"></i></span>' +
                    '</a>' +
                '</div>';
            }).join("")

            $section_new_courses.insertAdjacentHTML('beforeend', newCourses);

            posts_showed += response.length;
            $total_show_404.innerHTML = posts_showed;

            nextPage++;

            if(posts_showed >= total_post) $load_more_404.classList.add('hide');

        });

    }

    $load_more_404.addEventListener('click', loadMore);

}(this);