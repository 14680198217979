
var ajuda = {
 	init: function(){
		$('.ver-mais-ajuda').on('click',function(e){
			e.preventDefault();
			var $this = $(this);
			var qtd_perguntas = $perguntas = $this.parent().siblings('.d-none').length;
			var $perguntas = $this.parent()
								.siblings('.d-none')
								.slice(0,5)
								.toggleClass('d-none scale-in-ver-top');
			if (qtd_perguntas < 5) {
				$this.hide();
			}
		});
 	}
};
page_scripts['page-ajuda'] = ajuda;
