/*global page_scripts*/
/*eslint no-undef: "error"*/
/*eslint complexity: ["error", 10]*/
/*eslint max-depth: ["error", 7]*/
/*eslint max-len: ["error", { "ignoreStrings": true, "code": 170, "tabWidth": 9 }]*/
/*eslint max-lines-per-function: ["error", {"max": 41, "skipComments": true}]*/
/*eslint max-nested-callbacks: ["error", 3]*/
/*eslint max-params: ["error", 10]*/
/*eslint max-statements: ["error", 20]*/
/*eslint-env es6*/
/*eslint max-lines-per-function: ["error", 150]*/

var comoContratar = {
  init: function () {
    // Accordion
    $(".accordion-inner-box:not('.first') h4").click(function (e) {
      $(".accordion-inner-box:not('.first') div").slideUp();
      $(this).next("div").slideDown();
    });

    $("#accordion li h3").click(function (e) {
      $("#accordion li > div").slideUp();
      $(this).next("div").slideDown();
    });

    // Typewrite
    var TxtType = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = "";
      this.tick();
      this.isDeleting = false;
    };

    TxtType.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

      var that = this;
      var delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    window.onload = function () {
      var elements = document.getElementsByClassName("typewrite");
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute("data-type");
        var period = elements[i].getAttribute("data-period");
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
      // INJECT CSS
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
      document.body.appendChild(css);
    };
  },
};
page_scripts["page-como-contratar"] = comoContratar;
