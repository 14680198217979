/*******************************
 * Validacao form quero-ser-parceira
 *******************************/
var form_parceira = (function form_parceira() {

    function initialize() {
        $('section.quero-ser-parceira form input[type="submit"]').on('click', function (event) {
            if (!valida_form($('section.quero-ser-parceira form'))) {
                event.preventDefault();
                return false;
            }
            return true;
        });

        $('section.quero-ser-parceira form input').on('keyup', function() {
            var $this = $(this);
            var $ddd = $('section.quero-ser-parceira form').find('input[name="ddd"]');
            var $telefone = $('section.quero-ser-parceira form').find('input[name="telefone"]');
            if ($this.attr('name') == 'telefone' && $ddd.val()) {
                remove_error_input($this.parent(), $ddd.attr('name'));
            }
            if ($this.attr('name') == 'ddd' && $telefone.val()) {
                remove_error_input($this.parent(), $telefone.attr('name'));
            }
            remove_error_input($this.parent(), $this.attr('name'));
        });
    }

    function add_error_input($form, input_name, text) {
        var $input = $form.find('input[name="'+ input_name +'"]');
        $input.parent().addClass('error').find('input + span').text(text);
        $input.parent().find('span').css({ 'display': 'block', 'color': 'red', 'font-size': '15px', 'opacity': 1 });
    }

    function remove_error_input($form, input_name) {
        var $input = $form.find('input[name="'+ input_name +'"]');
        $input.parent().removeClass('error').find('span').text('');
    }

    function valida_form($form) {
        var str, telefone;
        var retorno = true;

        // Validando input nome
        str = $form.find('input[name="nome"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "nome", 'Campo obrigatório');
            retorno = false;
        }

        // Validando input email
        str = $form.find('input[name="email"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "email", 'Campo obrigatório');
            retorno = false;
        } else if (!isEmail(str)) {
            add_error_input($form, "email", 'E-mail incorreto');
            retorno = false;
        }

        // Validando campo telefone
        str = $form.find('input[name="ddd"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "ddd", 'Campo obrigatório');
            retorno = false;
        }
        telefone = $form.find('input[name="ddd"]').val() + '' + $form.find('input[name="telefone"]').val();
        telefone = telefone.replace(/[^0-9]/g, '');
        str = $form.find('input[name="telefone"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "telefone", 'Campo obrigatório');
            retorno = false;
        } else if (!isTel(telefone)) {
            add_error_input($form, "telefone", 'Telefone incorreto');
            retorno = false;
        }

        // Validando input cargo
        str = $form.find('input[name="cargo"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "cargo", 'Campo obrigatório');
            retorno = false;
        }

        // Validando input instituicao
        str = $form.find('input[name="instituicao"]').val();
        if (str.replace(/\s/g, '') === '') {
            add_error_input($form, "instituicao", 'Campo obrigatório');
            retorno = false;
        }
        
        return retorno;
    }

    return {"init": initialize};

})().init();

var state_city = (function () {
        
    function initialize() {
        // call_ajax(build_options_li_state);
        activate_selected();
    }

    function call_ajax(callback) {

        $.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados', function(response){
            json_state_city = response;
            if (typeof json_state_city == 'string')
                json_state_city = JSON.parse(json_state_city);
        
            callback();
        })
    }

    function build_options_li_state() {
        // percorre todas as chaves, retornando o html <li> concatenado com as chaves
        var html_li_state = json_state_city.map(function(obj){
            return '<li aria-value="' + obj.sigla + '">' + obj.sigla + '</li>';   
        }).join("");
        html_li_state = '<li aria-value="all" class="selected">Selecione</li>' + html_li_state;

        $('#select-estado').find('ul').append(html_li_state);
        
        $('.select-estado li').on('click', function() {
            $('.select-estado li').removeClass('selected');
            $(this).addClass('selected');
            $('.select-estado .dropbtn').find('span').text( $(this).text() );
            $('.select-estado #myDropdown').toggleClass('show');

            $('.select-cidade .dropbtn').find('span').text( 'Selecione' );
            
            return false;
        });

        build_options_li_city();
    }

    function build_options_li_city() {
        $('#content .quero-ser-parceira #select-estado li').click(function(event){
            event.preventDefault();

            var $filter_state = $('#content .quero-ser-parceira #select-estado');

            if($filter_state.find('li.selected').attr('aria-value').toLowerCase() != 'all'){
                var state_sigla = $filter_state.find('li.selected').text().toUpperCase();

                $('#content .quero-ser-parceira input[name="estado"]').val(state_sigla);

                $.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+ state_sigla +'/distritos', function(response){
                    
                    var html_li_city = '<li aria-value="all" class="selected">Selecione</li>';
                    $.each(response, function(key, obj){
                        html_li_city += '<li aria-value="' + obj.nome + '">' + obj.nome + '</li>'; 
                    });
                    
                    $('#select-cidade').find('ul').html(html_li_city);
                    $('.select-cidade .dropbtn').removeAttr('disabled', 'disabled');
    
                    $('.select-cidade li').on('click', function() {
                        $('#content .quero-ser-parceira input[name="cidade"]').val( $(this).text() );

                        $('.select-cidade li').removeClass('selected');
                        $(this).addClass('selected');
                        $('.select-cidade .dropbtn').find('span').text( $(this).text() );
                        $('.select-cidade #myDropdown').toggleClass('show');
                    });
                });
            } else {
                var html_li_city = '<li aria-value="all" class="selected">Selecione</li>';
                $('.select-cidade .dropbtn').attr('disabled', 'disabled');
                $('.select-cidade .dropbtn').find('span').text( 'Selecione' );
                $('#select-cidade').find('ul').html(html_li_city);
            }
        });
    }

    function activate_selected() {
        $('.select-dropdown .dropbtn').on('click', function() {
            $('.select-dropdown #myDropdown').not( $(this).parent().find('#myDropdown') ).removeClass('show');
            $(this).parent().find('#myDropdown').toggleClass('show');
            return false;
        });

        $('.select-dropdown input').on('keyup', function() {
            var filter, li;

            filter = $(this).val().toUpperCase();

            li = $(this).parent().find("li");
            li.each(function(index, elem) {
                if ($(elem).text().toUpperCase().indexOf(filter) > -1) {
                    $(elem).show();
                } else {
                    $(elem).hide();
                }
            });
        });
    }     

    return {
        "init": initialize
    }
})();
state_city.init();
