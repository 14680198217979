var kem;
(function($){
	$('input[type="text"][aria-group!="false"], input[type="color"][aria-group!="false"], input[type="date"][aria-group!="false"], input[type="datetime-local"][aria-group!="false"], input[type="email"][aria-group!="false"], input[type="month"][aria-group!="false"], input[type="number"][aria-group!="false"], input[type="password"][aria-group!="false"], input[type="tel"][aria-group!="false"], input[type="text"][aria-group!="false"], input[type="time"][aria-group!="false"], input[type="url"][aria-group!="false"], input[type="week"][aria-group!="false"]').each(function(){groupPravaler($(this));});
	$('select[aria-group!="false"]').each(function(){selectPravaler($(this));});
	$('input[type="radio"][aria-group!="false"]').each(function(){radioPravaler($(this));});
	$('input[type="checkbox"][aria-group!="false"]').each(function(){checkboxPravaler($(this));});
	$('.group-sanfona-pravaler').each(function(){sanfonaPravaler($(this));});

	$('body')
		.on('focus','input[type="text"], input[type="color"], input[type="date"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"]',function(){
			$(this).parent().addClass('group-focus');
	  }).on('keydown','input[type="text"], input[type="color"], input[type="date"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"]',function(){
			$(this).parent().addClass('group-fill').removeClass('group-error');
	  }).on('blur','input[type="text"], input[type="color"], input[type="date"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"]',function(){
			var v=$(this).val(), h=(v.trim()==="")?'group-focus group-fill':'group-focus' ;
			$(this).val(v.trim()).parent().removeClass(h);
			if($(this).hasClass('required')&&v.trim()==="")$(this).parent().addClass('group-error');
	  }).on('submit','form',function(e){
	  	$(this).find('input.required').each(function(index, el) {
	  		var $this = $(this);
	  		var valor = $this.val();
	  		if (valor == '') {
	  			$this.parent().addClass('group-error');
	  			e.preventDefault();
	  			return false;
	  		}else{
	  			$this.parent().removeClass('group-error');
	  		}
	  	});
	  }).on('mousedown','.group-password .group-password-eye',function(){
			$(this).parent().find("input").attr("type","text")
	  }).on('mouseup',function(){
			$('.group-password input').attr("type","password")
	  }).on('click','div.group-select-pravaler span.group-placeholder-pravaler, div.group-select-pravaler div.arrow-select',function(){
			var tempo;
			kem = $(this).parent();
			if(kem.hasClass('open')){
				kem.removeClass('open');
				tempo = setTimeout(function(){
					kem.removeAttr('style');
					$('#ghostPravaler').detach();
				}, 0);
			}else if(!kem.hasClass('disabled')){
				clearTimeout(tempo);
				var pos = kem.position(),
					wid = kem.width(),
					hid = kem.height(),
					wed = kem.outerWidth(true),
					hed = kem.outerHeight(true);
				kem
					.before('<div id="ghostPravaler" style="width:'+wed+'px;height:'+hed+'px;"></div>')
					.addClass('open')
					.css("top",pos.top)
					.css("left",pos.left)
					.css("z-index","9999")
					.css("width",wid)
					.css("position","absolute");
			}
	  }).on('click','div.group-select-pravaler ul li',function(){
	  		var kem = $(this).parent().parent(),
	  			sel = kem.find("select");
	  		tempo = setTimeout(function(){
					kem.removeAttr('style');
					$('#ghostPravaler').detach();
				}, 500);
			$(this).siblings().removeAttr("selected");
			$(this).attr("selected","selected").parent().parent().removeClass('open').find("span.group-placeholder-pravaler").text($(this).text());
			var selVal=$(this).attr("aria-value");
			sel.find("option").removeAttr("selected");
			sel.find("option[value='"+selVal+"']").attr("selected","selected");
			sel.val(selVal);
	  }).on('change','div.group-radio-pravaler input',function(){
			var nm = $(this).attr("name");
			$('div.group-radio-pravaler input[name="'+nm+'"]').each(function(){
				if($(this).is(':checked')){ $(this).parent().addClass("checked"); }
				else{ $(this).parent().removeClass("checked"); }
			});
	  }).on('change','div.group-checkbox-pravaler input',function(){
			if($(this).is(':checked')){ $(this).parent().addClass("checked"); }
			else{ $(this).parent().removeClass("checked"); }
	  });

	$(window).resize(function(){
		$('div.group-pravaler.input-button-pravaler').each(function(){
			var wt = $(this).innerWidth() - $(this).find(".bt-pravaler").outerWidth();
			$(this).find('input').css("width",wt);
			$(this).find('.group-placeholder-pravaler').css("width",wt);
		});
		$('.open').each(function(){$(this).removeClass("open").removeAttr('style');});
	});

	$("#bar-search #filtro-mobile").change(function (e) {
		if( $(this).val() == 0 ){
			$( "#filter-all" ).prop( "checked", true );
			$( "#filter-courses, #filter-universities, #filter-blog" ).prop( "checked", false );
		}else if( $(this).val() == 1 ){
			$( "#filter-courses" ).prop( "checked", true );
			$( "#filter-all, #filter-universities, #filter-blog").prop( "checked", false );
		}else if( $(this).val() == 2 ){
			$( "#filter-universities" ).prop( "checked", true );
			$( "#filter-all, #filter-courses, #filter-blog").prop( "checked", false );
		}else if( $(this).val() == 3 ){
			$( "#filter-blog" ).prop( "checked", true );
			$( "#filter-all, #filter-courses, #filter-universities" ).prop( "checked", false );
		}
	});

	$("#bar-search .search-filter .div-checkbox").on('click', function (e) {
		let elem = $(this).find('label > input');
		elem.prop('checked') ? elem.prop('checked', false) : elem.prop('checked', true);
	});

	function groupPravaler(el){
		var cl = el.attr("class");
		if(!cl) {return;}
		var rq = (cl.indexOf("required")>-1)?" required":"",
			lb = el.attr("aria-label"),
			ph = el.attr("placeholder"),
			ib = el.attr("aria-icon-before"),
			cb = (ib)?" icon-pravaler-before":"",
			ia = el.attr("aria-icon-after"),
			pf = (el.attr("aria-placeholder-fade"))?" group-placefade-pravaler":"",
			gl = (el.attr("aria-label"))?" group-label-pravaler":"",
			ca = (ia)?" icon-pravaler-after":"",
			pw = (el.attr("type")==="password")?" group-password":"",
			bt = el.attr("aria-button-id"),
			bc = (bt)?" input-button-pravaler":"",
			ds = (el.attr("disabled"))?" disabled":"";
		el.attr("class","form-pravaler"+rq).removeAttr("placeholder").removeAttr("aria-label");

		el.wrap($('<div/>',{'class':'group-pravaler '+cl+pw+ca+cb+bc+ds+pf+gl}));

		if(lb)el.after('<label class="group-label-pravaler">'+lb+'</label>');
		if(ph)el.after('<span class="group-placeholder-pravaler">'+ph+'</span>');
		if(el.attr("type")==="password")el.after('<span class="group-password-eye"></span>');
		if(ib)el.after('<span class="icon-pravaler icon-pravaler-before '+ib+'"></span>');
		if(ia)el.after('<span class="icon-pravaler icon-pravaler-after '+ia+'"></span>');
		if(bt){$("#"+bt).addClass("bt-pravaler").appendTo(el.parent());
				var wt = el.parent().innerWidth()-$("#"+bt).outerWidth();
				el.css("width",wt);
				el.parent().find(".group-placeholder-pravaler").css("width",wt);}
	}
	function selectPravaler(el){
		var lb = el.attr("aria-label"),
			ph = el.attr("placeholder"),
			cl = el.attr("class"),
			ds = (el.attr("disabled"))?" disabled":"";
		el.hide()
			.removeAttr("aria-label")
			.removeAttr("placeholder")
			.wrap($('<div/>',{'class':'group-select-pravaler '+cl+ds}));
			if(lb)el.after('<label class="group-label-pravaler">'+lb+'</label>');
		el.parent()
			.append('<ul></ul>')
			.append('<div class="arrow-select"></div>')
			.append('<span class="group-placeholder-pravaler">'+ph+'</span>');
		var ul = el.parent().find('ul');
		el.find("option").each(function(){
			var sel = ($(this).attr("selected"))?"aria-selected='selected' ":"",
				kem = ($(this).val()==="")?false:true;
			if(kem){
				ul.append("<li "+sel+" aria-value='"+$(this).val()+"'>"+$(this).html()+"</li>");
				if($(this).attr("selected")){
					ul.parent().find("span.group-placeholder-pravaler").text($(this).html());
				}
			}
		});

	}
	function radioPravaler(el){
		var lb = el.attr("aria-label"),
			cl = el.attr("class"),
			il = el.attr("id"),
			ds = (el.attr("disabled"))?" disabled":"",
			ch = (el.attr("checked"))?" checked":"";
		el.hide().removeAttr("aria-label").wrap($('<div/>',{'class':'group-radio-pravaler '+cl+ds+ch}));
		if(lb)el.after('<label class="group-label-pravaler" for="'+il+'">'+lb+'</label>');
		el.after('<label class="group-icon-pravaler" for="'+il+'"></label>');
	}
	function checkboxPravaler(el){
		var lb = el.attr("aria-label"),
			cl = el.attr("class"),
			il = el.attr("id"),
			ds = (el.attr("disabled"))?" disabled":"",
			ch = (el.attr("checked"))?" checked":"";
		el.hide().removeAttr("aria-label").wrap($('<div/>',{'class':'group-checkbox-pravaler '+cl+ds+ch}));
		if(lb)el.after('<label class="group-label-pravaler" for="'+il+'"><label class="group-icon-pravaler" for="'+il+'"></label>'+lb+'</label>');
	}
	function sanfonaPravaler(el){
		el.find(".box-pravaler").on('click',function(){
			$(this).siblings().removeClass("aberto-pravaler").find(".ocultar-pravaler").slideUp(250);
			$(this).toggleClass("aberto-pravaler").find(".ocultar-pravaler").slideToggle(550);
		});
	}

})(jQuery);


/*
 * --------------------------------------------------- *
 * ------------------ INICIO ------------------------- *
 * ----------- CODIGO PARA O SIMULADOR --------------- *
 * --------------------------------------------------- */

jQuery(document).ready(function($) {

    Number.prototype.formatMoney = function(c, d, t){
        var n = this,
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d == undefined ? "." : d,
            t = t == undefined ? "," : t,
            s = n < 0 ? "-" : "",
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
           return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };

    $('.renda').on("change mousemove input touchstart touchmove", function() {
        formatFields();
        var renda = parseFloat($('.renda').val());
        var step = setStep(renda);
        $('.renda').attr("step",step);
        calculaMensalidade();
    });

    $('.renda_val').on("keypress input paste",function(){
        $('.renda').removeAttr("step");
        var r = $('.renda_val').val();
        var renda = parseFloat(r.replace(/[^\d,-]/g, ''));
        var renda_garantidor = parseFloat($('.renda_garantidor').val());

        if(renda > 20000){
            renda = 20000;
            $('.renda_val').val('20.000,00');
        }
        //set the slider value
        $('.renda').val(renda);
        var x = event.which || event.keyCode;
        if(x == 13){
            formatFields();
        }
        calculaMensalidade();

    });

    $('.renda_garantidor').on("change mousemove input touchstart touchmove", function() {
		formatFields();
        var min = parseFloat(this.getAttribute('min')) || 0;
        var renda_g = parseFloat($('.renda_garantidor').val());
        var step = setStep(renda_g);
        $('.renda_garantidor').attr("step",step);
		/* ?????? I don't know because it's be here ?????? */
		// if(renda_g > 1100){
        //     min = 1000;
        // }
        $('.renda_garantidor').attr("min",min);
        calculaMensalidade();
    });

    $('.renda_garantidor_val').on("keypress input paste",function(){
        $('.renda_garantidor').removeAttr("step");
        var r = $('.renda_garantidor_val').val();
        var renda_garantidor = parseFloat(r.replace(/[^\d,-]/g, ''));
        var renda = parseFloat($('.renda').val());

        if(renda_garantidor > 20000){
            renda_garantidor = 20000;
            $('.renda_garantidor_val').val('20.000,00');
        }
        //set the slider value
        $('.renda_garantidor').val(renda_garantidor);
        var x = event.which || event.keyCode;
        if(x == 13){
            formatFields();
        }
        calculaMensalidade();

    });




/* $#$#$#$#$#$#$# TESTE A/B $#$#$#$#$#$#$# */
	$('article.Bloco-simulador-mensalidade .btnCadastrar.foraDoPopUp').on("click",function(){
		if($(this).attr("disabled")!="disabled"){
			$(this).attr("disabled","disabled");
			var data = {
				'action': 'set_simulador_AB',
				'cmd': 'bt_cadastrar',
				'id': sid,
				'security': sec
			};
			$.post(ajaxurl, data, function (response) {
				console.log(data);
				window.location = "https://www.pravaler.com.br/cadastre-se/";
			});
		}
		return false;
	});

	$('article.Bloco-simulador-mensalidade .btnCadastroIfrmaeCall.foraDoPopUp').on("click",function(){
		if($(this).attr("disabled")!="disabled"){
			$(this).attr("disabled","disabled");
			var data = {
				'action': 'set_simulador_AB',
				'cmd': 'bt_cadastrar',
				'id': sid,
				'security': sec
			};
			$.post(ajaxurl, data, function (response) {
				console.log(data);
			});
		}
	});

	$('article.Bloco-simulador-mensalidade').on("click", "section.popup-juros.grafico .btnEntendi", function(){
		if($(this).attr("disabled")!="disabled"){
			$(this).attr("disabled","disabled");
			var data = {
				'action': 'set_simulador_AB',
				'cmd': 'popup_grafico_bt_entendi',
				'id': sid,
				'security': sec
			};
			$.post(ajaxurl, data, function (response) {
				console.log(data);
				window.location = "https://www.pravaler.com.br/cadastre-se/";
			});
		}
	});

	$('article.Bloco-simulador-mensalidade').on("click", "section.popup-juros.grafico .btnAjuda", function(){
		if($(this).attr("disabled")!="disabled"){
			$(this).attr("disabled","disabled");
			var data = {
				'action': 'set_simulador_AB',
				'cmd': 'popup_grafico_bt_ajuda',
				'id': sid,
				'security': sec
			};
			$.post(ajaxurl, data, function (response) {
				console.log(data);
				return true;
			});
		}
		return true;
	});

	$('article.Bloco-simulador-mensalidade').on("click", "section.popup-juros.tabela .btnEntendi", function(){
		if($(this).attr("disabled")!="disabled"){
			$(this).attr("disabled","disabled");
			var data = {
				'action': 'set_simulador_AB',
				'cmd': 'popup_tabela_bt_entendi',
				'id': sid,
				'security': sec
			};
			$.post(ajaxurl, data, function (response) {
				console.log(data);
				window.location = "https://www.pravaler.com.br/cadastre-se/";
			});
		}
	});

	$('article.Bloco-simulador-mensalidade').on("click", "section.popup-juros.tabela .btnAjuda", function(){
		if($(this).attr("disabled")!="disabled"){
			$(this).attr("disabled","disabled");
			var data = {
				'action': 'set_simulador_AB',
				'cmd': 'popup_tabela_bt_ajuda',
				'id': sid,
				'security': sec
			};
			$.post(ajaxurl, data, function (response) {
				console.log(data);
				return true;
			});
		}
		return true;
	});


	$('article.Bloco-simulador-mensalidade .popup-juros div.fechar').on("click",function(){
		$("article.Bloco-simulador-mensalidade .popup-juros").hide()
	});
	
/* $#$#$#$#$#$#$# TESTE A/B $#$#$#$#$#$#$# */







    function setStep(renda) {
        var step = 1;
        if(renda < 2500) {step = 50; }
        else{ step = 100; }
        return step;
    }

    formatFields = function() {
        var renda = parseFloat($('.renda').val());
        var renda_garantidor = parseFloat($('.renda_garantidor').val());

        $(".renda_val").val(renda.formatMoney(2,',','.'));
        $(".renda_garantidor_val").val(renda_garantidor.formatMoney(2,',','.'));
    }

    calculaMensalidade = function() {
        var renda = parseFloat($('.renda').val());
        var renda_garantidor = parseFloat($('.renda_garantidor').val());
		var mensalidade = (renda + renda_garantidor) / 2;

        if(mensalidade > 12000) { mensalidade = 12000; }

        $(".resultado").html('R$ ' + mensalidade.formatMoney(2,',','.'));
    }

    formatFields();
    calculaMensalidade();
});

/*
 * --------------------------------------------------- *
 * ------------------- FINAL ------------------------- *
 * ----------- CODIGO PARA O SIMULADOR --------------- *
 * --------------------------------------------------- */

(function($){
	j=$(window);
	w=j.width();
	h=j.height();
	filterHeight(w,h);
	j.resize(function(){
		w=j.width();
		h=j.height();
		filterHeight(w,h);
	});
	function filterHeight(w,h){
		var a=$(".bar-search form .box"),
			b=$(".bar-search form .box .search-result");

   		if(typeof a[0] !== 'undefined' && typeof b[0] !== 'undefined'){

			var oTop = (a.offset().top == 0) ? 180 : 180;
			var pTop = (b.offset().top == 0 || typeof b.offset() !== 'undefined' ) ? 320 : 320;

			var r=(w<992)?h-oTop:500;     
			var s=r-(pTop-oTop)-40;

	   		a.css("height",r+"px");
	   		b.css("height",s+"px");
	   	}
	}

	$('#bloco-IES .carousel-item').each(function(){
		// Temporário
		var next = $(this).next();
		var total = $('#bloco-IES').data('total');
		if (!next.length) {next=$(this).siblings(':first');}
		next.children(':first-child').clone().appendTo($(this));
		for(var i=0;i<total;i++){
			next=next.next();
			if (!next.length) {next=$(this).siblings(':first');}
			next.children(':first-child').clone().appendTo($(this));
		}
	});

})(jQuery);

(function($){
	/* carousel */
    pravalerCarouselINIT();
    $(window).on("resize",function(e){pravalerCarouselINIT();});
    function pravalerCarouselINIT(){
        var ww=$(window).width();
        $(".pravaler-carousel").each(function(index){
            var w=$(this).width(),
                s=$(this).find(".pravaler-inner"),
                btp=$(this).find(".bt_prev"),
                btn=$(this).find(".bt_next"),
                i=s.find(".pravaler-item"),
                ul=$(this).find("ul"),
                qi=i.length,
                a=(s.attr("data-active"))?parseInt(s.attr("data-active")):1,
                v=(s.attr("data-visible"))?parseInt(s.attr("data-visible")):1,
                vlg=(s.attr("data-lg-visible"))?parseInt(s.attr("data-lg-visible")):v,
                v=(ww<992)?v:vlg,
                lv=(v>1)?qi-v+1:qi;
            s.css("max-width",parseInt((w/v)*qi)+"px")
             .css("width",parseInt((w/v)*qi)+"px")
             .find(".pravaler-item")
             .width(parseInt((w/v)));
            if(a<=1){btp.fadeOut(150);}else{btp.fadeIn(150);}
            if(a>=lv){btn.fadeOut(150);}else{btn.fadeIn(150);}
            ul.attr("class","");
            if(qi<=6){ul.attr("class","col-2 offset-5 d-flex");}
            else if(qi<=9){ul.attr("class","col-4 offset-4 d-flex");}
            else if(qi<=16){ul.attr("class","col-6 offset-3 col-lg-4 offset-lg-4 d-flex");}
            else if(qi<=25){ul.attr("class","col-8 offset-2 col-lg-6 offset-lg-3 d-flex");}
            else{ul.attr("class","col-10 offset-1 d-flex");}
        });
    }
    $(".pravaler-carousel .bts").on("click",function(e){
        var ww=$(window).width();
        var c=$(this).parent(), /* carousel */
            s=c.find(".pravaler-inner"), /* slide */
            btp=c.find(".bt_prev"),  /* botão previous */
            btn=c.find(".bt_next"),  /* botão next */
            w=c.width(), /* width do carousel */
            i=s.find(".pravaler-item"), /* itens */
            wi=i.width(), /* width dos itens */
            qi=i.length, /* quantidade de itens */
            a=(s.attr("data-active"))?parseInt(s.attr("data-active")):1, /* item ativo */
            v=(s.attr("data-visible"))?parseInt(s.attr("data-visible")):1, /* qt itens visiveis */
            vlg=(s.attr("data-lg-visible"))?parseInt(s.attr("data-lg-visible")):v, /* qt itens visiveis pro desk */
            v=(ww<992)?v:vlg,
            lv=(v>1)?qi-v+1:qi,
            t=($(this).hasClass("bt_next"))?a+1:a-1, /* target */
            t=(t<1)?1:t,
            t=(t>lv)?lv:t,
            p=((t-1)*wi), /* posição absoluta*/
            f=0;
        i.width(parseInt((w/v)));
        s.attr("data-active",t)
         .css("max-width",parseInt((w/v)*qi)+"px")
         .css("width",parseInt((w/v)*qi)+"px")
         .css("left","-"+p+"px");
        c.find("ul li.active").removeClass("active");
        for(f=0;f<v;f++){
            c.find("ul li:eq("+(t+f-1)+")").addClass("active");
        }
        if(t<=1){btp.fadeOut(150);}else{btp.fadeIn(150);}
        if(t>=lv){btn.fadeOut(150);}else{btn.fadeIn(150);}
	});
	
	$('#carousel-IES .arrow-carousel-IES').on('click', function(e){
		e.preventDefault();
	});

	$("#chatbots .site-header, .help_chat").on("click",function(e){
		var m = $("#chatbot");
		if(m.hasClass("clicked")){
			m.css('z-index','79000')
			.removeClass("clicked")
			.find(".content")
			.html('');
		}else{
			m.css('z-index','90000')
			.addClass("clicked")
			.find(".content")
			.html('<iframe id="body-frame" allow="geolocation; microphone; camera" frameborder="0" style="width: 100%; height: 100%; z-index:4000; position:absolute;" src="https://pravaler-dnk.nubitalk.com/ClickToInteract/chat.aspx?onecontactinstance=pravaler-dnk&amp;motive=Site"></iframe>');

			t = setInterval(wordsAtWind, 1000);

			$("#iframeChatBot").load(function(){
        		$(this).addClass("carregado")
       			clearInterval(t);
    		});

			truckPlate = [
				"Carregando as configurações...",
				"Carregando as configurações...",
				"Carregando as configurações...",
				"Carregando as configurações...",
				"Aguarde um instante, estamos carregando as informações...",
				"Aguarde um instante, estamos carregando as informações...",
				"Aguarde um instante, estamos carregando as informações...",
				"Localizando um consultor...",
				"Localizando um consultor...",
				"Localizando um consultor...",
				"Localizando um consultor...",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve.",
				"Todos os nossos consultores estão ocupados neste momento, vamos te atender em breve."
			];

    		function wordsAtWind(){
    			var i = $("#iframeChatBot").attr("data-plate") * 1;
    			i=(i>=truckPlate.length)?0:i+1;
    			$("#chatbot").find("p").text(truckPlate[i]);
    			$("#iframeChatBot").attr("data-plate",i);
    		}
		}


	});


	$(".button-RI-duvidas").on("click",function(e){
		$("#chatbot .header, .help_chat").click();
	});

	$(".button-fale-com-RI").on("click",function(e){
		var m = $("#bloco-form-fale-com-RI");
		if(m.hasClass("clicked")){
			m.css('display','none').removeClass("clicked");
		}else{
			m.css('display','block').addClass("clicked");
		}
	});

	$(window).on("load","#iframeChatBot",function(){
        alert("IFrame is on loaded");
        alert("IFrame is on loaded");
        alert("IFrame is on loaded");
    });


	/* SUBMENU HEADER */
	$("div.menu-header-principal ul.navbar-nav li.menu-item-has-children a").on("click",function(e){

		$(this).parent().toggleClass('clicado').siblings().removeClass('clicado');
	});

})(jQuery);