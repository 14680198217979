/*global page_scripts*/

var sejaParceira = {
    init:function(){
         $('.btnModalParceira').on('click', function (e) {
            e.preventDefault();
            var target = $(this).data('target');
            $('#' + target).fadeIn("slow");
            $('body').css('overflow', 'hidden');
        });
        
        $('.modal-parceiras, .modal-parceiras .close span').on('click', function (e) {
            e.preventDefault();
            if (e.target === this) {
                $('body').css('overflow', '');
                $('.modal-parceiras:visible').fadeOut("slow");
            }
        });

        let swipersPartnersSchools = {
            slidesPerView: 1.75,
            rows: 1,
            breakpoints: {
                // when window width is >= 992px
                768: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                    allowSlideNext: false,
                    allowSlidePrev: false,
                    allowTouchMove: false,
                    //enabled: false
                },
            },
        };
        
        let swipersBenefitsSchools = {
            slidesPerView: 1.25,
            rows: 1,
            breakpoints: {
                // when window width is >= 992px
                768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                    allowSlideNext: false,
                    allowSlidePrev: false,
                    allowTouchMove: false,
                    //enabled: false
                },
            },
        };

        document.addEventListener("DOMContentLoaded", function (e) {
            beginSwipers();
        });

        function beginSwipers() {
            /*global Swiper, swipersComoFunciona */
            swipersComoFunciona = new Swiper(
                "#partners-schools-carrousel",
                swipersPartnersSchools
            );

            swipersComoFuncionaTeste = new Swiper(
                "#benefits-schools-carrousel",
                swipersBenefitsSchools
            );
        }
    }
};
page_scripts['page-seja-uma-parceira'] = sejaParceira;
