/*global isName */

var $ = jQuery.noConflict();
if (url_site != null) {
    var ajaxurl = url_site + "/wp-admin/admin-ajax.php";
}
var url_site;
var page_scripts = {};
var json_state_city_unv = null;

/* ############################# */
/*      Registro de eventos      */
/* ############################# */
var save_event = (function () {
    function save_in_ga(e_category, e_action, e_label, e_value) {
        if(arguments.length < 2 ) return false
        ga_options = {}
        if(e_category) ga_options.event_category = e_category
        if(e_label) ga_options.event_label = e_label
        if(e_value) ga_options.value = parseInt(e_value)
        if(typeof gtag !== "undefined") {
            ga_options.event_callback = function(){ console.log("event tracking: ", e_category, e_action, e_label, e_value) }
            gtag('event', e_action, ga_options)
        }else{
            console.log("prv google analytics event ERROR: " + typeof gtag)
        }
    }
    return { "in_ga": save_in_ga }
})();
/* ############################# */

var search = (function () {

    function initialize() {
        map_search_checkbox();
    }

    function map_search_checkbox() {
        var $all_filters_ckb = jQuery('form#search .group-checkbox-pravaler.filter-all');
        var $filters_ckb = jQuery('form#search .group-checkbox-pravaler').find('.filter-courses, .filter-universities, .filter-blog');

        $all_filters_ckb.on('click', function () {
            jQuery(this).attr('checked', 'checked');
            $all_filters_ckb.addClass('checked');
            $filters_ckb.find('input').removeAttr('checked');
            $filters_ckb.removeClass('checked');
        });
    }

    return {
        "init": initialize
    }
})();
search.init();

var lps_call_iframe = (function () {
    function appendIframeCadastro(local, id) {
        var url_origem = window.location.href;

        var $iframe = $('<iframe id="iFrameCadastro" class="col-12" height="730px" src="https://cadastro.pravaler.com.br/?origin=' + encodeURIComponent(url_origem) + '&ies=' + id + '&iframe=true&utm_source=organic&utm_medium=softlead&utm_campaign=pagina-das-ies"></iframe>');

        $local = local;
        $('iframe#iFrameCadastro').remove();
        $(local).before($iframe);
        $iframe.get(0).scrollIntoView({behavior: 'smooth', block: 'center'});
    }

    function initialize() {
        $btnCadastro = $('.btnCadastroIfrmaeCall');
        if ($('#embed-iframe-location').length) {
            $local = $('#embed-iframe-location');
        } else if ($('.page-unv-single .box-preg-freq').prev().length) {
            $local = $('.page-unv-single .box-preg-freq').prev();
        } else if ($('#duvidas-frequentes-univ').length) {
            $local = $('#duvidas-frequentes-univ');
        } else {
            $local = $('.curso-single .duvidas-frequentes');
        }
        if ($btnCadastro.length && $local.length) {
            $btnCadastro.on('click', function (event) {
                event.preventDefault();
                $local.css('visibility', 'visible');
                appendIframeCadastro($local, $('#ies_code').val());
            });
        }
    }

    return {
        "init": initialize
    }
})().init();



var category = (function () {
    function atualizaNumeros(numberOfResults) {
        var $contadorTextLocation = $('p.col-12');
        var $self = $('#carrega-category');
        var totalPosts;
        var numbers = $contadorTextLocation.text().match(/\d+/g).map(Number);
        if (numbers !== null) {
            totalPosts = numbers[1];
            qtdPosts = numbers[0];
        }
        numberOfResults = numberOfResults + qtdPosts;
        if (numberOfResults >= totalPosts) {
            $contadorTextLocation.text('Você viu todos os ' + totalPosts + ' posts');
            $self.hide();
        } else {
            $self.attr('data-page', parseInt($self.attr('data-page')) + 1);
            $contadorTextLocation.text('Você viu ' + numberOfResults + ' de ' + totalPosts);
        }
    }

    function initialize() {
        $('select[name="categories"]').on('change', function(){
            window.location.href = $(this).val();
        });
        
        $('#carrega-category').on('click', function (e) {
            e.preventDefault();

            // Animation loading spin
            $(".bloco4-pagination .loading-result").css({'display' : 'block','margin-bottom' : '50px'});

            var self = this;
            var $self = $(this);
            var data = {
                'action': 'prasaber_more',
                'keyPravaler': $self.attr('data-keypravaler'),
                'paged': (parseInt($self.attr('data-page')) + 1),
                'catId': parseInt($self.attr('data-id_categoria')),
                'post_tag': $self.attr('data-post_tag')
            };

            var $location = $('.container.bloco3-noticias .cards-noticias');
            var onDone = function (data) {
                var arrayPosts = data;
                var numberOfResults = arrayPosts.length;
                setTimeout(function () {

                    // Animation loading spin
                    $(".bloco4-pagination .loading-result").css('display', 'none');

                    $.each(data, function (i, e) {
                        var $section = $('.card-category').first().clone();
                        $section
                            .find('a')
                            .attr({
                                    href: e['url'],
                                    title: e['title'],
                                });
                        $section.find('.dsprv-card-blog-img a').empty().append(e['thumb']);
                        $section.find('.dsprv-card-blog-div-texts h3.dsprv-card-blog-div-subtitle-date').text(e['title']);
                        $section.find('.dsprv-card-blog-div-texts .content-post').text(e['content']);
                        $section
                            .find('time')
                            .attr({
                                datetime: e['date_full'],
                                content: e['date']
                            })
                            .html( e['date'] );
                        $section
                            .find('span.author.vcard')
                            .attr('content',e['author']);
                        $location.append($section);
                        $section.css('opacity', 0)
                            .animate(
                                {opacity: 1},
                                {queue: false, duration: 1000}
                            );
                    });
                    atualizaNumeros(numberOfResults);
                }, 500);
            };

            var ajaxCall = {
                request: '',
                numberOfCalls: 0,
                callback: '',
                call: function () {
                    var self = this;
                    if (this.numberOfCalls <= 9) {
                        this.numberOfCalls++;
                        this.request = $.ajax({
                            url: ajaxurl,//'',
                            type: 'POST',
                            dataType: 'json',
                            data: data,
                        })
                            .fail(function () {
                                return self.call();
                            })
                            .done(self.callback);
                    }
                },
                getData: function (callback) {
                    this.callback = callback;
                    return this.call();
                }
            };
            ajaxCall.getData(onDone);
        });
    }

    return {"init": initialize};
})().init();

var pag_unv_cursos = (function () {
    var $section = '';

    function get_html_section(data) {
        $section = $section.clone();
        $section.find('a').attr({
            id: 'post-' + data.id,
            href: data.url,
            title: data.title,
        });
        $section.find('a').find('h4').text(data.title);
        return $section;
    }

    function set_html_section() {
        $section = $('#cursos-univ a[id^=post]').parent().first();
    }

    function atualizaNumeros(numberOfResults) {
        var $contadorTextLocation = $('#univ-cursos-vistos');
        var $self = $('#carrega-cursos');
        var totalPosts;
        var numbers = $contadorTextLocation.text().match(/\d+/g).map(Number);
        if (numbers !== null) {
            totalPosts = numbers[1];
            qtdPosts = numbers[0];
        }
        numberOfResults = numberOfResults + qtdPosts;
        if (numberOfResults >= totalPosts) {
            $contadorTextLocation.text('Você viu todos os ' + totalPosts + ' posts');
            $self.hide();
        } else {
            $self.attr('data-page', parseInt($self.attr('data-page')) + 1);
            $contadorTextLocation.text('Você viu ' + numberOfResults + ' de ' + totalPosts);
        }
    }

    function initialize() {
        set_html_section();
        $('#carrega-cursos').on('click', function (event) {
            event.preventDefault();

            // Animation loading spin
            $('section#cursos-univ .loading-result').css('display','block');
            
            var self = this;
            var $self = $(this);
            var data = {
                'action': 'unv_cursos_more',
                'keyPravaler': $self.attr('data-keypravaler'),
                'paged': (parseInt($self.attr('data-page')) + 1),
                'unv_id': parseInt($self.attr('data-id_universidade'))
            };
            var $location = $('#cursos-univ h3.cursos-tag-categoria + .col-12');
            var onDone = function (data) {
                var arrayPosts = data;
                var numberOfResults = arrayPosts.length;
                setTimeout(function () {

                    // Animation loading spin
                    $('section#cursos-univ .loading-result').css('display','none');

                    $.each(data, function (i, e) {
                        var $section = $(get_html_section(e));
                        $location.append($section);
                        $section.css('opacity', 0)
                            .animate(
                                {opacity: 1},
                                {queue: false, duration: 1000}
                            );
                    });
                    atualizaNumeros(numberOfResults);
                }, 500);
            };

            var ajaxCall = {
                request: '',
                numberOfCalls: 0,
                callback: '',
                call: function () {
                    var self = this;
                    if (this.numberOfCalls <= 9) {
                        this.numberOfCalls++;
                        this.request = $.ajax({
                            url: ajaxurl,//'',
                            type: 'POST',
                            dataType: 'json',
                            data: data,
                        })
                            .fail(function () {
                                return self.call();
                            })
                            .done(self.callback);
                    }
                },
                getData: function (callback) {
                    this.callback = callback;
                    return this.call();
                }
            }
            ajaxCall.getData(onDone);
        });
    }

    return {"init": initialize};
})().init();

var pag_unv_campus_e_polos = (function () {
    var $section = '';

    function get_html_section(data) {
        $section = $section.clone();
        $section.find('h3').text(data.title);
        $section.find('div.campus-polos > div').attr('id', 'item-' + data.id);
        $section.find('h4.address').html(data.endereco);
        $section.find('h4.phone').html(data.telefone);
        return $section;
    }

    function set_html_section() {
        $section = $('.dados_campus').first();
    }

    function atualizaNumeros(numberOfResults) {
        var $contadorTextLocation = $('#univ-campus-e-polos-vistos');
        var $self = $('#carrega-campus-e-polos');
        var totalPosts;
        var numbers = $contadorTextLocation.text().match(/\d+/g).map(Number);
        if (numbers !== null) {
            totalPosts = numbers[1];
            qtdPosts = numbers[0];
        }
        numberOfResults = numberOfResults + qtdPosts;
        if (numberOfResults >= totalPosts) {
            $contadorTextLocation.text('Você viu todos os ' + totalPosts + ' polos');
            $self.hide();
        } else {
            $self.attr('data-page', parseInt($self.attr('data-page')) + 1);
            $contadorTextLocation.text('Você viu ' + numberOfResults + ' de ' + totalPosts);
        }
    }

    function initialize() {
        set_html_section();
        $('#carrega-campus-e-polos').on('click', function (event) {
            event.preventDefault();
            
            // Animation loading spin
            $('section#campus-polos .loading-result').css('display','block');

            var self = this;
            var $self = $(this);
            var data = {
                'action': 'unv_campus_e_polos_more',
                'keyPravaler': $self.attr('data-keypravaler'),
                'paged': (parseInt($self.attr('data-page')) + 1),
                'unv_id': parseInt($self.attr('data-id_universidade'))
            };
            var $location = $('#campus-polos div.col-12.pravaler-inner');
            var onDone = function (data) {
                var arrayPosts = data;
                var numberOfResults = arrayPosts.length;
                setTimeout(function () {
                    $('.ajax-loader.bg-icon-loader-lighter').remove();

                    // Animation loading spin
                    $('section#campus-polos .loading-result').css('display','none');
                    
                    $.each(data, function (i, e) {
                        var $section = $(get_html_section(e));
                        $location.append($section);
                        $section.css('opacity', 0)
                            .animate(
                                {opacity: 1},
                                {queue: false, duration: 1000}
                            );
                    });
                    atualizaNumeros(numberOfResults);
                }, 500);
            };

            var ajaxCall = {
                request: '',
                numberOfCalls: 0,
                callback: '',
                call: function () {
                    var self = this;
                    if (this.numberOfCalls <= 9) {
                        this.numberOfCalls++;
                        this.request = $.ajax({
                            url: ajaxurl,//'',
                            type: 'POST',
                            dataType: 'json',
                            data: data,
                        })
                            .fail(function () {
                                return self.call();
                            })
                            .done(self.callback);
                    }
                },
                getData: function (callback) {
                    this.callback = callback;
                    return this.call();
                }
            }
            ajaxCall.getData(onDone);
        });
    }

    return {"init": initialize};
})().init();


/*******************************
 * Ajax "PRV_INTERESSE"
 *******************************/
// var api_prv_interesse = (function() {
    var form_data = null;
    var grecaptcha_form_id = {
        selector_html: null,
        callback_function: null
    };

    var checkForErrors = function ($form, data) {
        if($form.attr('id') == 'entre_em_contato'){
            $.each(data.errors, function (index, val) {
                $input = $form.find("input[name='" + index + "']");
                $input.parent().addClass('error');
                var feedbackMessage = handleFeedbackErrorMessage(index, val);
                $input.siblings('span').text(feedbackMessage);
            });
        } else {
            $.each(data.errors, function (index, val) {
                if (index == 'telefone') {
                    $form.find("input[name='ddd']").parent().addClass('group-error');
                    $form.find("input[name='telefone']").parent().addClass('group-error');
                } else {
                    $form.find("input[name='" + index + "']").parent().addClass('group-error');
                }
                var feedbackMessage = handleFeedbackErrorMessage(index, val);
                $form.find(".feedback-message.feedback-message-" + index).html(feedbackMessage);
            });
        }
    };

    function handleFeedbackErrorMessage(field, string) {
        if (string == 'Este parâmetro é obrigatório') {
            // Validação de campo obrigatório
            return 'O campo '+ field  +' é obrigatório';
        }
        if (string.includes('deve conter no mínimo 10 dígitos')) {
            // Api já retorna uma string amigável
            return string;
        }
        if (string.includes('deve conter no máximo 11 dígitos')) {
            // Api já retorna uma string amigável
            return string;
        }
        if (string.includes('não é um numeric válido')) {
            // Validação de campo númerico
            return 'O campo '+ field  +' está inválido';
        }
        if (field == 'email' && string.includes('não é um email válido')) {
            // Validação de campo númerico
            return 'E-mail inválido';
        }
        if (string.includes('tem que estar completo')) {
            // Validação de sobrenome
            return 'Insira também o sobrenome';
        }
    }

    function envia_api_interesse_redirect_cadastro() {
        var url = $("#form-proposal").attr('action');
        url += "/?proposal_params=";
        var dataSerialized = $("#form-proposal").serializeArray();
        var data = dataSerialized.reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
          }, {});


        ajax_post($("#form-proposal"), data, async(ret, form) => {
            if (ret.token) {
              let newDataSerialized = dataSerialized.filter((d) => d.name !== 'urlOrigin').map((ds) => {
                  return {key: window.btoa(ds.name), value: window.btoa(ds.value)}
              })
              const encrypted = window.btoa(JSON.stringify(newDataSerialized));
              window.location.href = url + encrypted;
            }
        });
    }

    function valida_form_interesse(event) {   
        event.preventDefault();
        const fields = [];
        fields["nome"] = {
            required: true,
            name: "Nome",
            validateFunction: isName,
        };
        fields["email"] = {
            required: true,
            name: "E-mail",
            validateFunction: isEmail,
        };
        fields["cpf"] = { 
            required: true, 
            name: "CPF", 
            validateFunction: isCPF 
        };
        fields["telefone"] = {
            required: true,
            name: "Celular",
            validateFunction: isTel,
        };

        if (valida_global_form($("#form-proposal"), fields)) {
            return true;
        }

        return false;
        }

        function validate_required_field($form, fields, item) {
        if (
            fields[item.name] &&
            fields[item.name]["required"] &&
            item.value.replace(/\s/g, "") === ""
            ) {
            $form
                .find(".feedback-message.feedback-message-" + item.name)
                .html(`O campo '${fields[item.name]['name']}' é obrigatório!`);
                
            $form
                .find(`input[name="${item.name}"]`)
                .parent()
                .addClass("group-error");

            return false;
        } else {
            $form
                .find(`input[name="${item.name}"]`)
                .parent()
                .find("span")
                .removeClass(["group-error", "group-fill"])
                .html("");
            
            return true;    
        }
        }

        function is_field_valid($form, fields, item) {
        if (fields[item.name] && fields[item.name]["validateFunction"]) {
            if (fields[item.name]["validateFunction"](item.value)) {
            $form
                .find(`input[name="${item.name}"]`)
                .parent()
                .find("span")
                .removeClass(["group-error", "group-fill"])
                .html("");

            return true;
            } else {
            $form
                .find(`input[name="${item.name}"]`)
                .parent()
                .addClass("group-error")
                .removeClass("group-fill");
            $form
                .find(`.feedback-message.feedback-message-${item.name}`)
                .html(`${fields[item.name]['name']} inválido`);
            return false;
            }
        }
        return true;
        }

    function valida_global_form($form, fields) {
        let retArray = [];
        $form.serializeArray().forEach(function (item) {
            validate_required_field($form, fields, item)
            ? retArray.push(is_field_valid($form, fields, item))
            : retArray.push(false);
        });
        return typeof(retArray.find((ar) => ar === false)) === "undefined";
        }

        function valida_form($form, addPlaceholder) {
        var str, telefone;
        var retorno = true;

        // Validando input nome
        str = $form.find('input[name="nome"]').val();
        if (str.replace(/\s/g, "") === "") {
            $form.find('input[name="nome"]').parent().addClass("group-error");
            if (addPlaceholder)
            $form
                .find('input[name="nome"]')
                .attr("placeholder", "Campo obrigatório");
            retorno = false;
        }

        // Validando input email
        str = $form.find('input[name="email"]').val();
        if (str.replace(/\s/g, "") === "") {
            $form.find('input[name="email"]').parent().addClass("group-error");
            if (addPlaceholder)
            $form
                .find('input[name="email"]')
                .attr("placeholder", "Campo obrigatório");
            retorno = false;
        } else if (!isEmail(str)) {
            $form.find('input[name="email"]').parent().addClass("group-error");
            if (addPlaceholder)
            $form
                .find(".feedback-message.feedback-message-email")
                .html("E-mail inválido");
            retorno = false;
        }

      // Validaçãoando cpf
      var $cpf = $form.find('input[name="cpf"]');
      str = $cpf.val();
      if ($cpf.length && str.replace(/\s/g, "") === "") {
        $form.find('input[name="cpf"]').parent().addClass("group-error");
        if (addPlaceholder)
          $form
            .find('input[name="cpf"]')
            .attr("placeholder", "Campo obrigatório");
        retorno = false;
      } else if ($cpf.length && !isCPF(str)) {
        $form.find('input[name="cpf"]').parent().addClass("group-error");
        if (addPlaceholder)
          $form
            .find(".feedback-message.feedback-message-cpf")
            .html("O campo cpf está inválido");
        retorno = false;
      }

      // Validando campo telefone
      str = $form.find('input[name="ddd"]').val();
      if (str.replace(/\s/g, "") == "") {
        $form.find('input[name="ddd"]').parent().addClass("group-error");
        retorno = false;
      }
      telefone =
        $form.find('input[name="ddd"]').val() +
        "" +
        $form.find('input[name="telefone"]').val();
      telefone = telefone.replace(/[^0-9]/g, "");
      str = $form.find('input[name="telefone"]').val();
      if (str.replace(/\s/g, "") === "") {
        $form.find('input[name="telefone"]').parent().addClass("group-error");
        if (addPlaceholder)
          $form
            .find('input[name="telefone"]')
            .attr("placeholder", "Campo obrigatório");
        retorno = false;
        console.log('tel replace === ""');
      } else if (!isTel(telefone)) {
        $form.find('input[name="ddd"]').parent().addClass("group-error");
        $form.find('input[name="telefone"]').parent().addClass("group-error");
        if (addPlaceholder)
          $form
            .find(".feedback-message.feedback-message-telefone")
            .html("O campo telefone está inválido");
        retorno = false;
      }

      // validando termos de condições
      var termos_condicoes = $form.find('input[name="termos_condicoes"]');
      if (termos_condicoes.length && !termos_condicoes.is(":checked")) {
        var labelError = $form.find(".check01 span.error");
        $(".check01").addClass("error");
        if (!labelError.length) {
          $form
            .find('input[name="termos_condicoes"]')
            .parent(".check-error")
            .append(
              '<span class="feedback-message error text-danger">Campo Obrigatório!</span>'
            );
        } else {
            $form.find('input[name="termos_condicoes"]').parent().parent().find('label.error').remove();
            $('.check01').removeClass("error");
        //   $form.find(".check01 span.error").html("Campo Obrigatório!");
        }
        retorno = false;
      } else {
        $form
          .find('input[name="termos_condicoes"]')
          .parent()
          .parent()
          .find("label.error")
          .remove();
        $(".check01").removeClass("error");
      }
      return retorno;
    }

    function ajax_post($form, form_data, callback) {

        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/";

        form_data['action'] = 'save_apipravaler_leadproposta';
       
        var jqxhr = $.ajax({ url: baseUrl + "wp-admin/admin-ajax.php", type: 'POST', dataType: 'json', global: false, data: form_data,})
        .done(function (data) {
            // Animation loading spin
            $('.loading-result').css('display', 'block');
      
            $('.loading-result').css('display', 'none');
      
            if (typeof data.errors !== "undefined") {
              checkForErrors($form, data);
            } else {
              $form.find('input:not("[type=submit]")').val("");
              callback(data, $form);
            }

        }).fail(function (data) {
            checkForErrors($form, data.responseJSON);
        });

        return jqxhr.responseJSON;
    }

    $(document).on({
        ajaxStart: function(){
            $('.formInputs input[type="submit"]').attr('disabled', 'disabled');
            $('.formInputs input').attr('disabled', 'disabled').css('color','#d3d3d3');
            $('.formInputs select[name="como_soube"]').parent().css('pointer-events','none');
            $('section.entrem-contato-comigo form input').attr('disabled', 'disabled').css('color','#d3d3d3');
            $('section.entrem-contato-comigo form input[type="submit"]').attr('disabled', 'disabled');
        },
        ajaxStop: function(){
            $('.formInputs input[type="submit"]').removeAttr('disabled', 'disabled');
            $('.formInputs input').removeAttr('disabled', 'disabled').css('color','#000');
            $('.formInputs select[name="como_soube"]').parent().css('pointer-events','visible');
            $('section.entrem-contato-comigo form input').removeAttr('disabled', 'disabled').css('color','#000');
            $('section.entrem-contato-comigo form input[type="submit"]').removeAttr('disabled', 'disabled');
        }
    });

$(document).ready(function(){

    /* Functions para retornar o json com todos os Estados e suas respectivas Cidades de acordo com o que
        existir cadastrado no WP.
        só será requisitado se estiver na página de Universidades */
    $element = $('#content #primary.page-universidades');
    if(!$element.length){
        return;
    }

    $('#select-cidade').parent().addClass('disabled');

    var state_city_unv = (function () {

        function initialize() {
            // call_ajax(build_options_li_state);
            // build_options_li_city();
        }

        function call_ajax(callback) {
            var data = {
                'action': 'filter_state_city_unv'
            }

            $.post(ajaxurl, data, function(response){
                json_state_city_unv = response;
                callback();
            })
        }

        function build_options_li_state(){
            // armazena as chaves do objeto (DF, SP, MG...)

            if (typeof json_state_city_unv == 'string')
                json_state_city_unv = JSON.parse(json_state_city_unv);

            var keys_state = Object.keys(json_state_city_unv);

            // percorre todas as chaves, retornando o html <option> concatenado com as chaves
            var html_options_state = keys_state.map(function(state){
                return '<option value="' + state + '">' + state + '</option>';
            }).join("");

            // percorre todas as chaves, retornando o html <li> concatenado com as chaves
            var html_li_state = keys_state.map(function(state){
                return '<li aria-value="' + state + '">' + state + '</li>';
            }).join("");

            $('#select-estado').append(html_options_state);
            $('#select-estado').parent().find('ul').append(html_li_state);
        }

        function build_options_li_city(){
            $('body').on('click', '#filtro-estado .group-select-pravaler ul li', function(){

                if($('#select-estado').val() != 'all'){
                    $('#select-cidade').parent().removeClass('disabled');
                    $('.removable-option-city').remove();
                    $('.removable-li-city').remove();
                    $('#select-cidade').parent().find('.group-placeholder-pravaler').text('Cidade');

                    var city_values = json_state_city_unv[$('#select-estado').val()];

                    var html_options_city = city_values.map(function(city){
                        return '<option class="removable-option-city" value="' + city + '">' + city + '</option>';
                    }).join("");

                    var html_li_city = city_values.map(function(city){
                        return '<li class="removable-li-city" aria-value="' + city + '">' + city + '</li>';
                    }).join("");

                    $('#select-cidade').find('.removable-option-city').remove();
                    $('#select-cidade').parent().find('.removable-li-city').remove();
                    $('#select-cidade').append(html_options_city);
                    $('#select-cidade').parent().find('ul').append(html_li_city);
                }
                else {
                    $('#select-cidade').parent().addClass('disabled');
                    $('.removable-option-city').remove();
                    $('.removable-li-city').remove();
                    $('#select-cidade').parent().find('.group-placeholder-pravaler').text('Cidade');
                }

            });
        }

        return {
            "init": initialize
        }
    })();
    state_city_unv.init();

});
