var descomplica = {
	init: function() {

        generateSelect();

        $('.form-descomplica .wpcf7 input[type="submit"]').click(function() {
            if (!valida_form()) {
                event.preventDefault();
            } else {
                $('.form-descomplica .loading-box').show();
                $('.form-descomplica #btn-submit').attr('disabled', 'disabled');
                $('.form-descomplica input, .form-descomplica select, .form-descomplica textarea')
                    .not('.form-descomplica .wpcf7-form input[type=submit]')
                    .each(function() {
                    $(this).parent().addClass('disabled');
                    $(this).attr('readonly', 'true');
                });
                $('.form-descomplica input[type=submit]').addClass('disabled');
            }
        });
    
        if($('.screen-reader-response').html() != "" || $('.wpcf7-response-output').html()) {
            $('.form-descomplica .formInputs').hide();
            $('.form-descomplica .formSucesso').fadeIn('fast');
        } else {
            $('.form-descomplica .formInputs').fadeIn('fast');
            $('.form-descomplica .formSucesso').hide();
        }

        $('.group-pravaler input').keypress(function(event) {
            if($(this).parent().hasClass('only_num')){
                var charCode = (event.which) ? event.which : event.keyCode
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                    return false;
            }
            return true;
        });

        $('.form-textarea textarea').click(function() {
            if ($(this).parent().hasClass('group-error')) {
                $(this).parent().removeClass('group-error');
            }
        })

        $('body').on('click', '.form-descomplica div.custom-select-descomplica div', function() {
            $(this).parent().parent().removeClass('group-error');
        });

        function valida_form() {
            var retorno = true;
            $('.form-descomplica .wpcf7-form .form-input input, .form-descomplica .wpcf7-form .form-select select, .form-descomplica .wpcf7-form .form-textarea textarea')
                .not('.form-descomplica .wpcf7-form input[type=submit]')
                .each(function() {

                var value = $(this).val();
                $(this).parent().removeClass('mb-0');
                if (!$(this).is('select') && !$(this).is('textarea')) {
                    $(this).parent().parent().find('span.message-error').remove();
                } else {
                    $(this).parent().find('span.message-error').remove();
                }

                if($(this).parent().hasClass('required') && value === null) {
                    $(this).parent().find('span.message-error').remove();
                    $(this).parent().addClass('group-error');
                    appendMessageError('Campo obrigatório', $(this).parent());
                    retorno = false;
                } else if ($(this).is('textarea') && value.replace(/\s/g, '') === '') {
                    $(this).parent().find('span.message-error').remove();
                    $(this).parent().addClass('group-error');
                    appendMessageError('Campo obrigatório', $(this).parent());
                    retorno = false;
                } else if ($(this).parent().hasClass('required') && value.replace(/\s/g, '') === '') {
                    $(this).parent().parent().find('span.message-error').remove();
                    appendMessageError('Campo obrigatório', $(this).parent().parent());
                    retorno = false;
                } else if (!$(this).is('select') && !$(this).is('textarea')) {
                    if ($(this).attr('name') == 'cpf' && !isCPF(value)) {
                        appendMessageError('CPF inválido', $(this).parent().parent());
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'nome' && value.split(' ').length <= 1) {
                        appendMessageError('Nome inválido, insira sobrenome', $(this).parent().parent());
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'ddd' && value.length < 2) {
                        appendMessageError('DDD inválido', $(this).parent().parent());
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'telefone' && !isTel(value)) {
                        appendMessageError('Telefone inválido', $(this).parent().parent());
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'email' && !isEmail(value)) {
                        appendMessageError('E-mail inválido', $(this).parent().parent());
                        retorno = false;
                    }
                    if ($(this).attr('name') == 'cep' && value.length < 8) {
                        appendMessageError('CEP inválido', $(this).parent().parent());
                        retorno = false;
                    }
                }
            });
            if (!$('.form-descomplica .check01 input').is(':checked')) {
                appendMessageError('Campo obrigatório', $('.form-descomplica .check01 input').parent().parent());
                retorno = false;
            } else {
                $('.form-descomplica .check01 input').parent().parent().find('span.message-error').remove();
            }
            return retorno;
        }

        function appendMessageError(message, $element) {
            if ($element.find('span.message-error').length) {
                $element.find('span.message-error').remove();
            }
            $element.find('.group-pravaler').addClass('group-error mb-0');
            $element.append('<span class="tx-body-s txcolor-white pl-1 txcolor-white message-error">'+ message +'</span>');
        }

        function isEmail(e){
            var r = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return r.test(e);
        }

        function isTel(t){
            if (t.length < 9) return false;
            return true;
        }
    
        function isCPF(f){
            var s=0,r;
            if(f=="00000000000") return false;
            for(i=1;i<=9;i++)s=s+parseInt(f.substring(i-1,i))*(11-i);
            r=(s*10)%11;
            if((r==10)||(r==11)) r=0;
            if(r!=parseInt(f.substring(9,10))) return false;
            s=0;
            for(i=1;i<=10;i++)s=s+parseInt(f.substring(i-1,i))*(12-i);
            r=(s*10)%11;
            if((r==10)||(r==11))r=0;
            if(r!=parseInt(f.substring(10, 11))) return false;
            return true;
        }

        function generateSelect() {
            var x, i, j, selElmnt, a, b, c;
            /*look for any elements with the class "custom-select":*/
            x = document.getElementsByClassName("custom-select-descomplica");
            for (i = 0; i < x.length; i++) {
                selElmnt = x[i].getElementsByTagName("select")[0];
                /*for each element, create a new DIV that will act as the selected item:*/
                a = document.createElement("DIV");
                a.setAttribute("class", "select-selected");
                a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
                x[i].appendChild(a);
                /*for each element, create a new DIV that will contain the option list:*/
                b = document.createElement("DIV");
                b.setAttribute("class", "select-items select-hide");
                for (j = 1; j < selElmnt.length; j++) {
                    /*for each option in the original select element,
                    create a new DIV that will act as an option item:*/
                    c = document.createElement("DIV");
                    c.innerHTML = selElmnt.options[j].innerHTML;
                    c.addEventListener("click", function(e) {
                        /*when an item is clicked, update the original select box,
                        and the selected item:*/
                        var y, i, k, s, h;
                        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                        h = this.parentNode.previousSibling;
                        for (i = 0; i < s.length; i++) {
                            if (s.options[i].innerHTML == this.innerHTML) {
                                s.selectedIndex = i;
                                h.innerHTML = this.innerHTML;
                                y = this.parentNode.getElementsByClassName("same-as-selected");
                                for (k = 0; k < y.length; k++) {
                                    y[k].removeAttribute("class");
                                }
                                this.setAttribute("class", "same-as-selected");
                                break;
                            }
                        }
                        h.click();
                    });
                    b.appendChild(c);
                }
                x[i].appendChild(b);
                a.addEventListener("click", function(e) {
                    /*when the select box is clicked, close any other select boxes,
                    and open/close the current select box:*/
                    e.stopPropagation();
                    closeAllSelect(this);
                    this.nextSibling.classList.toggle("select-hide");
                    this.classList.toggle("select-arrow-active");
                });
            }
        }
            
        function closeAllSelect(elmnt) {
            /*a function that will close all select boxes in the document,
            except the current select box:*/
            var x, y, i, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            for (i = 0; i < y.length; i++) {
                if (elmnt == y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < x.length; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }
        
        /*if the user clicks anywhere outside the select box,
        then close all select boxes:*/
        document.addEventListener("click", closeAllSelect);
    }    
}
page_scripts['page-descomplica'] = descomplica;